import axios from "axios";
import { API_URL } from "../../utils/config";
import qs from "qs";

const token = `Bearer ${localStorage.getItem("token")}`;

const headers = {
  headers: {
    authorization: token,
  },
};

const getDataGuru = async (params) => {
  let endpoint = `${API_URL}/guru?${qs.stringify(params)}`;
  return await axios.get(endpoint, headers);
};

const getDataGuruById = async (id) => {
  let endpoint = `${API_URL}/guru/${id}`;
  return await axios.get(endpoint, headers);
};

const updateDataGuru = async (payload, id) => {
  let endpoint = `${API_URL}/guru/${id}`;
  return await axios.put(endpoint, payload, headers);
};

const importGuru = async (payload) => {
  let endpoint = `${API_URL}/guru/import-excel`;
  return await axios.post(endpoint, payload, headers);
};

const guruService = {
  getDataGuru,
  getDataGuruById,
  updateDataGuru,
  importGuru,
};

export default guruService;
