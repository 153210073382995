import { createSlice } from "@reduxjs/toolkit";

const teacherReducer = createSlice({
  name: "guru",
  initialState: {
    value: {
      results: [],
      results_found: 0,
    },
  },
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setData } = teacherReducer.actions;
export default teacherReducer.reducer;
