import React from "react";
import logo from "../../assets/images/logo.png";

function SidenavBrand() {
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Zie Exam</span>
      </div>
      <hr />
    </>
  );
}

export default SidenavBrand;
