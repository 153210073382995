import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from "antd";
import axios from "axios";
import { useState } from "react";
import { openNotificationWithIcon } from "../components/notification/customNotification";
import resetPasswordService from "../services/auth/reset-password";
import { API_URL } from "../utils/config";

function ForgotPassword() {
  const [error, setError] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const onFinish = async (values) => {
    setLoadingButton(true);
    try {
      await resetPasswordService.sendEmail(values);
      openNotificationWithIcon("Berhasil", "Silahkan check email anda", <CheckCircleOutlined style={{ color: "#52c41a" }} />)
    } catch (error) {
      if(error.response.status === 422) {
        openNotificationWithIcon("Peringatan", "Silahkan cek kembali formulir anda", <WarningOutlined style={{ color: "yellow" }} />)
        setError(error.response?.data?.errors)
      }
      else if(error.response.status === 404)
        openNotificationWithIcon("Failed", "Email tidak dapat ditemukan !", <WarningOutlined style={{ color: '#ff0000' }} />)
      else 
        openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />)
    } finally {
      setLoadingButton(false);
    }
  }

  const formItemProps = (key) => {
    return {
      name: key,
      validateStatus: error[key] ? "error" : null,
      help: error?.[key]?.[0],
    }
  };

  return (
    <Layout className="layout-default layout-signin" style={{ minHeight: "100vh" }}>
      <Layout.Content className="signin">
        <Row gutter={[24, 0]} style={{ justifyContent: "center" }}>
          <Col span={8}>
            <Card>
              <Typography.Title level={3}>Reset Password</Typography.Title>
              <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item label="Email" {...formItemProps("email")}>
                  <Input placeholder="example@mail.com" />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button type="primary" htmlType="submit" loading={loadingButton}>Kirim</Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default ForgotPassword;