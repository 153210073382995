import {
  AudioFilled,
  DeleteFilled,
  EditFilled,
  PictureFilled,
  VideoCameraFilled,
} from "@ant-design/icons";
import { Button, Card, Col, Image, Row } from "antd";
import React, { useRef } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch } from "react-redux";
import {
  openImageUploader,
  setFocusRange,
} from "../../store/features/soal-editor";
import styles from "./SoalEditor.module.css";

export default function FormSoal(props) {
  const { soal, setSoal } = props;
  const dispatch = useDispatch();
  const editorRef = useRef();

  const openImageUploaderHandler = () => {
    dispatch(
      openImageUploader({
        image: soal.gambar,
        onSave: (image) => {
          setSoal((soal) => ({ ...soal, gambar: image }));
        },
      })
    );
  };

  const editorChangeHandler = (e) => {
    let val = e.target.value;
    let content = val === "<div><br></div>" || val === "<br>" ? "" : val;
    setSoal((soal) => ({ ...soal, teks: content }));
  };

  const pasteHandler = (e) => {
    var clipboardData, pastedData;
    e.stopPropagation();
    e.preventDefault();
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData("Text");
    window.document.execCommand("insertText", false, pastedData);
  };

  const removeImage = () => setSoal((soal) => ({ ...soal, gambar: null }));

  return (
    <Card>
      <Row gutter={[8, 8]}>
        <Col
          xs={{ span: 24 }}
          md={{ span: soal.gambar ? 6 : 4 }}
          lg={{ span: soal.gambar ? 6 : 3 }}
        >
          {soal && soal.gambar ? (
            <div className={styles["soal-image-container"]}>
              <div className={styles["soal-image-tools"]}>
                <Button
                  type="primary"
                  icon={<EditFilled />}
                  onClick={openImageUploaderHandler}
                />
                <Button
                  type="primary"
                  icon={<DeleteFilled />}
                  onClick={removeImage}
                />
              </div>
              <Image
                src={soal.gambar}
                alt="Gambar Soal"
                className={styles["soal-image"]}
              />
            </div>
          ) : (
            <div className={styles["soal-button-container"]}>
              <Button
                className={styles["soal-button"]}
                icon={<PictureFilled />}
                onClick={openImageUploaderHandler}
                type="primary"
              >
                Gambar
              </Button>
              {/* <Button
                className={styles["soal-button"]}
                icon={<AudioFilled />}
                type="primary"
              >
                Audio
              </Button>
              <Button
                icon={<VideoCameraFilled />}
                className={styles["soal-button"]}
                type="primary"
              >
                Video
              </Button> */}
            </div>
          )}
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: soal.gambar ? 18 : 20 }}
          lg={{ span: soal.gambar ? 18 : 21 }}
        >
          <div
            className={styles["soal-editor-wrapper"]}
            onClick={() => editorRef.current.focus()}
          >
            <ContentEditable
              html={soal.teks}
              innerRef={editorRef}
              className={`${styles["soal-editor"]} math-appendable`}
              placeholder="Ketik pertanyaan"
              onChange={editorChangeHandler}
              onPaste={pasteHandler}
              onFocus={() => dispatch(setFocusRange())}
              onDrop={(e) => e.preventDefault()}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
