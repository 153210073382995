import axios from "axios";
import qs from "qs";
import { API_URL } from "../../utils/config";

const getDataKompetensiDasar = async (params, config) => {
  let endpoint = `${API_URL}/kompetensi-dasar?${qs.stringify(params)}`;
  return await axios.get(endpoint, config);
};

const postDataKompetensiDasar = async (data) => {
  let endpoint = `${API_URL}/kompetensi-dasar`;
  return await axios.post(endpoint, data);
};

const kompetensiDasarService = {
  getDataKompetensiDasar,
  postDataKompetensiDasar,
};

export default kompetensiDasarService;
