import { Drawer, Empty, message, Pagination, Row, Spin } from "antd";
import axios from "axios";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import soalService from "../../services/api/soal";
import {
  setData as setDataSoal,
  setPagination,
} from "../../store/features/soal-selector";
import CardAddSoal from "./CardAddSoal";

const SoalSelector = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { onAddSoal: addSoalHandler, selectedIds } = props;
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: dataSoal, pagination } = useSelector(
    (state) => state.soalSelector
  );

  useImperativeHandle(ref, () => ({
    setVisible: (isVisible) => setVisible(isVisible),
  }));

  const fetchDataSoal = useCallback(
    async (signal = new AbortController().signal) => {
      setLoading(true);
      try {
        let params = {
          currentPage: pagination.currentPage,
          limit: pagination.pageSize,
          ...selectedMapel,
        };
        let res = await soalService.getDataSoal(params, { signal: signal });
        dispatch(
          setDataSoal({
            results: res.data.results,
            results_found: res.data.results_found,
          })
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          message.error("Tidak dapat memuat data.");
        }
      }
      setLoading(false);
    },
    [dispatch, pagination, selectedMapel]
  );

  const paginationChangeHandler = (page, pageSize) => {
    dispatch(setPagination({ currentPage: page, pageSize: pageSize }));
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataSoal(controller.signal);
    return () => controller.abort();
  }, [fetchDataSoal, pagination]);

  return (
    <Drawer
      title="Tambah Soal"
      placement="right"
      width={520}
      onClose={() => setVisible(false)}
      visible={visible}
    >
      <Row gutter={[10, 10]}>
        {/* <Col span={8}>
          <List
            header={<Typography.Text>Kompetensi Dasar</Typography.Text>}
            dataSource={dataKD}
            renderItem={(item) => (
              <List.Item>
                <NavLink to={"/hello"}>{item.kompetensi_dasar}</NavLink>
              </List.Item>
            )}
          />
        </Col> */}
        {/* <Col span={16}> */}
        {dataSoal?.results?.length > 0 && (
          <Pagination
            style={{ margin: "0.5rem 0 1rem" }}
            current={pagination.currentPage}
            total={dataSoal.results_found}
            pageSize={pagination.pageSize}
            onChange={paginationChangeHandler}
          />
        )}
        {!loading ? (
          <>
            {dataSoal.results.map((soal, index) => {
              return !selectedIds.includes(soal.id) ? (
                <CardAddSoal
                  isAdded={false}
                  onClickAdd={() => addSoalHandler(soal)}
                  soal={soal}
                  key={index}
                />
              ) : null;
            })}
            {dataSoal.results.map((soal, index) => {
              return selectedIds.includes(soal.id) ? (
                <CardAddSoal
                  isAdded
                  onClickAdd={() => addSoalHandler(soal)}
                  soal={soal}
                  key={index}
                />
              ) : null;
            })}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {dataSoal?.results?.length <= 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <Empty description={"Tidak ada soal"} />
          </div>
        )}
        {/* </Col> */}
      </Row>
    </Drawer>
  );
});

export default SoalSelector;
