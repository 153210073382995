import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, message, Row, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CardLoadingUjian from "../components/loaders/CardLoadingUjian";
import CardUjian from "../components/paket-ujian/CardUjian";
import SelectMapel from "../components/settings/SelectMapel";
import ujianService from "../services/api/ujian";
import { setDataUjian } from "../store/features/paket-ujian";

export default function PaketUjian() {
  const dispatch = useDispatch();
  const { dataUjian } = useSelector((state) => state.paketUjian);
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );
  const [loading, setLoading] = useState(false);

  const fetchDataUjian = useCallback(
    async (signal = new AbortController().signal) => {
      try {
        setLoading(true);
        let params = { ...selectedMapel };
        let res = await ujianService.getDataUjian(params, { signal: signal });
        let data = res.data.results.map((ujian) => {
          return {
            ...ujian,
            soal_json: JSON.parse(ujian.soal_json),
          };
        });
        dispatch(setDataUjian(data));
      } catch (error) {
        if (!axios.isCancel(error)) {
          message.error("Tidak dapat memuat data.");
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, selectedMapel]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchDataUjian(controller.signal);
    return () => controller.abort();
  }, [fetchDataUjian]);

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "1rem" }}
      >
        <Col>
          <Typography.Title level={3}>Data Paket Ujian</Typography.Title>
          <div style={{ paddingBottom: "1rem" }}>
            <SelectMapel />
          </div>
        </Col>
        <Col>
          <NavLink to={"/paket-ujian/buat-ujian"}>
            <Button size="large" type="primary" icon={<PlusCircleFilled />}>
              Buat Paket Ujian
            </Button>
          </NavLink>
        </Col>
      </Row>
      {!loading ? (
        dataUjian.map((ujian, index) => <CardUjian key={index} ujian={ujian} />)
      ) : (
        <CardLoadingUjian />
      )}
    </>
  );
}
