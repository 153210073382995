import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from "qs";

const token = `Bearer ${localStorage.getItem("token")}`;

const headers = {
  headers: {
    authorization: token,
  },
};

const pembelajaranService = {
  getDataPembelajaran: async (params, signal) => {
    const endpoint = `${API_URL}/pembelajaran?${qs.stringify(params)}`;
    return await axios.get(endpoint, { signal, ...headers });
  },
  postDataPembelajaran: async (data) => {
    const endpoint = `${API_URL}/pembelajaran`;
    return await axios.post(endpoint, data, headers);
  },
  getDataPembelajaranByUser: async (data, signal) => {
    const endpoint = `${API_URL}/pembelajaran/guru`;
    return await axios.post(endpoint, data, { signal, ...headers });
  },
};

export default pembelajaranService;
