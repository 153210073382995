import { Menu } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import navigations from "../../routes/sidenav/navigations";

function SidenavMenus(props) {
  const { color } = props;
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.authUser);

  const getColor = (pathname, pathItem) => {
    if (pathname === pathItem) return color;
    return "";
  };

  return (
    <Menu theme="light" mode="inline">
      {navigations.map((item, index) => {
        let countMenu = 0;
        let menuList = [];
        item.items.map((subItem, subIndex) => {
          return subItem?.middleware?.accept.includes(user?.level) ||
            !subItem?.middleware?.accept
            ? menuList.push(
                <Menu.Item key={subItem.label + subIndex}>
                  <NavLink to={subItem.path} exact>
                    <span
                      className="icon"
                      style={{
                        background: getColor(pathname, subItem.path),
                      }}
                    >
                      {subItem.icon}
                    </span>
                    <span className="label">{subItem.label}</span>
                  </NavLink>
                </Menu.Item>
              ) && countMenu++
            : null;
        });
        return countMenu !== 0 ? (
          <Fragment key={index}>
            <Menu.Item className="menu-item-header" key={index}>
              {item.name}
            </Menu.Item>
            {menuList.map((item) => item)}
          </Fragment>
        ) : null;
      })}
    </Menu>
  );
}

export default SidenavMenus;
