import React, { useEffect } from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import LoaderAuth from "../pages/LoaderAuth";
import { isAuthenticated } from "../store/features/auth-user";
import { setSekolahId, setSemesterId } from "../store/features/app-setting";

export default function ProtectedRoute({ children, middleware, ...rest }) {
  const defaultPath = "/";
  const user = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      !user.authenticated &&
      user.status === "rejected" &&
      middleware.type === "auth"
    ) {
      history.push(`/login`);
      openNotification();
    }
    if (user.authenticated && user.user?.sekolah_id)
      dispatch(setSekolahId(user.user.sekolah_id));
    if (user.authenticated && user.user?.semester_id)
      dispatch(setSemesterId(user.user.semester_id));
  }, [user]);

  const openNotification = () => {
    notification.info({
      message: "Session expired, please login again",
      placement: "top",
    });
  };

  useEffect(() => {
    // if (!user.authenticated) {
      dispatch(isAuthenticated());
    // }
  }, [history]);

  const commonCase = (location) => {
    return middleware.protected && middleware.type === "auth" ? (
      LoaderAuth
    ) : middleware.protected &&
      middleware.type === "guest" &&
      user.authenticated ? (
      <Redirect to={{ pathname: defaultPath, state: { from: location } }} />
    ) : middleware.protected &&
      middleware.type === "guest" &&
      user.status === "rejected" ? (
      children
    ) : middleware.protected && middleware.type === "guest" ? (
      <></>
    ) : null;
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        middleware?.accept ? (
          middleware.protected &&
          middleware.type === "auth" &&
          middleware.accept.includes(user.user.level) ? (
            children
          ) : middleware.protected &&
            middleware.type === "auth" &&
            !middleware.accept.includes(user.user.level) ? (
            <Redirect
              to={{ pathname: defaultPath, state: { from: location } }}
            />
          ) : (
            commonCase(location)
          )
        ) : middleware.protected &&
          middleware.type === "auth" &&
          user.authenticated ? (
          children
        ) : (
          commonCase(location)
        )
      }
    />
  );
}
