import { createSlice } from "@reduxjs/toolkit";

const getSelectionRange = () => {
  let range = null;
  if (window.getSelection && window.getSelection().rangeCount) {
    range = window.getSelection().getRangeAt(0);
  } else if (document.createRange) {
    range = document.createRange();
  }
  return range;
};

const soalEditor = createSlice({
  name: "soal-editor",
  initialState: {
    soalId: null,
    loading: false,
    visible: false,
    isMultipleAnswer: false,
    focusRange: null,
    MIN_OPSI: 4,
    MAX_OPSI: 5,
    imageUploaderModal: { visible: false, image: null, onSave: () => {} },
    mathEditorModal: { visible: false },
  },
  reducers: {
    setEditorVisible: (state, action) => {
      let isVisible = action.payload;
      return {
        ...state,
        visible: action.payload,
        soalId: !isVisible ? null : state.soalId,
        focusRange: !isVisible ? null : state.focusRange,
        isMultipleAnswer: !isVisible ? false : state.isMultipleAnswer,
      };
    },
    openImageUploader: (state, action) => {
      return {
        ...state,
        imageUploaderModal: {
          visible: true,
          image: action.payload.image,
          onSave: action.payload.onSave,
        },
      };
    },
    closeImageUploader: (state, action) => {
      return {
        ...state,
        imageUploaderModal: {
          visible: false,
          image: null,
          onSave: (image) => {},
        },
      };
    },
    setFocusRange: (state, action) => {
      let range = getSelectionRange();
      let container = range.commonAncestorContainer;
      let isAppendable =
        (container && container.classList.contains("math-appendable")) ||
        (container.parentNode &&
          container.parentNode.classList.contains("math-appendable"));
      let focusRange = isAppendable ? range : state.focusRange;
      return { ...state, focusRange };
    },
    openMathEditor: (state, action) => {
      return { ...state, mathEditorModal: { visible: true } };
    },
    closeMathEditor: (state, action) => {
      return { ...state, mathEditorModal: { visible: false } };
    },
    setIsMultipleAnswer: (state, action) => {
      return { ...state, isMultipleAnswer: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
    editSoal: (state, action) => {
      return { ...state, soalId: action.payload, visible: true };
    },
  },
});

export const {
  setEditorVisible,
  openImageUploader,
  closeImageUploader,
  setFocusRange,
  openMathEditor,
  closeMathEditor,
  setIsMultipleAnswer,
  setLoading,
  editSoal,
  setOpsi,
} = soalEditor.actions;
export default soalEditor.reducer;
