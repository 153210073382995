import {
  ExclamationCircleOutlined,
  KeyOutlined,
  LogoutOutlined,
  SettingOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Dropdown,
  List,
  Modal,
  Row,
  Space,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import SekolahSelectOption from "../../pages/rombel/components/SekolahSelectOption";
import { resetData } from "../../store/features/auth-user";
import { API_URL } from "../../utils/config";
import IconToggler from "../icons/IconToggler";

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1890ff;
  }
  .ant-btn-success {
    background-color: #52c41a;
  }
  .ant-btn-yellow {
    background-color: #fadb14;
  }
  .ant-btn-black {
    background-color: #262626;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
  .ant-switch-active {
    background-color: #1890ff;
  }
`;

const profile = [
  <svg
    width="40"
    height="40"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

function Header({ placement, name, subName, handleSidenavColor, onPress }) {
  // Component
  const { Title, Text } = Typography;
  // Global State
  const user = useSelector((state) => state.authUser.user);
  const appSetting = useSelector((state) => state.appSetting);
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [visible, setVisible] = useState(false);

  // Methods
  // const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const logout = (e) => {
    e.preventDefault();

    Modal.confirm({
      title: "Konfirmasi",
      icon: <ExclamationCircleOutlined />,
      content: "Apakah ada ingin keluar dari halaman ini?",
      okText: "Keluar",
      cancelText: "Batal",
      onOk() {
        localStorage.removeItem("token");
        dispatch(resetData());
        history.push("/login");
      },
    });
  };

  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      {/* <div className="setting-drawer" onClick={showDrawer}>
        {setting}
      </div> */}
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          {/*  */}
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
            style={{ marginLeft: "1rem" }}
          >
            {<IconToggler />}
          </Button>
          <Dropdown
            overlay={
              <List
                min-width="100%"
                className="header-notifications-dropdown "
                itemLayout="horizontal"
              >
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      user?.picture ? (
                        <Avatar
                          shape="square"
                          src={API_URL + "images/uploads/" + user?.picture}
                        />
                      ) : (
                        profile
                      )
                    }
                    title={user?.nama}
                    description="online"
                  />
                </List.Item>
                <Link to="/Profile">
                  <List.Item>
                    <Col span={4}>
                      <SettingOutlined
                        style={{ fontSize: "1.15rem", color: "#1890ff" }}
                      />
                    </Col>
                    <Col span={20}>
                      <Typography.Text
                        style={{
                          color: "rgba(0, 0, 0, 0.65)",
                          fontWeight: "bold",
                        }}
                      >
                        Pengaturan Profile
                      </Typography.Text>
                    </Col>
                  </List.Item>
                </Link>
                <Link to="/forgot-password">
                  <List.Item>
                    <Col span={4}>
                      <KeyOutlined
                        style={{ fontSize: "1.15rem", color: "#1890ff" }}
                      />
                    </Col>
                    <Col span={20}>
                      <Typography.Text
                        style={{
                          color: "rgba(0, 0, 0, 0.65)",
                          fontWeight: "bold",
                        }}
                      >
                        Reset Password
                      </Typography.Text>
                    </Col>
                  </List.Item>
                </Link>
                <Link to="/logout" onClick={logout}>
                  <List.Item>
                    <Col span={4}>
                      <LogoutOutlined
                        style={{ fontSize: "1.15rem", color: "#1890ff" }}
                      />
                    </Col>
                    <Col span={20}>
                      <Typography.Text
                        style={{
                          color: "rgba(0, 0, 0, 0.65)",
                          fontWeight: "bold",
                        }}
                      >
                        Logout
                      </Typography.Text>
                    </Col>
                  </List.Item>
                </Link>
              </List>
            }
            trigger={["click"]}
          >
            <a
              href="#pablo"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              style={{ color: "#111827" }}
            >
              <Space direction="horizontal">
                {profile}
                <Text className="subtitle">{user?.nama}</Text>
              </Space>
            </a>
          </Dropdown>
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            placement={placement}
            visible={visible}
          >
            <div layout="vertical">
              <div className="header-top">
                <Title level={4}>
                  Pengaturan
                  <Text className="subtitle">See our dashboard options.</Text>
                </Title>
              </div>

              <div className="sidebar-color">
                <Title level={5}>Sidebar Color</Title>
                <div className="theme-color mb-2">
                  <ButtonContainer>
                    <Button
                      type="primary"
                      onClick={() => handleSidenavColor("#1890ff")}
                    >
                      1
                    </Button>
                    <Button
                      type="success"
                      onClick={() => handleSidenavColor("#52c41a")}
                    >
                      1
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => handleSidenavColor("#d9363e")}
                    >
                      1
                    </Button>
                    <Button
                      type="yellow"
                      onClick={() => handleSidenavColor("#fadb14")}
                    >
                      1
                    </Button>
                    <Button
                      type="black"
                      onClick={() => handleSidenavColor("#111")}
                    >
                      1
                    </Button>
                  </ButtonContainer>
                </div>
              </div>
              <div className="sidebar-data">
                {user?.level === "admin" ? <SekolahSelectOption /> : null}
              </div>
            </div>
          </Drawer>
        </Col>
      </Row>
    </>
  );
}

export default Header;
