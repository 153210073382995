import { DownloadOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, message, Row, Space } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GuruDetailModal from "../components/guru/GuruDetailModal";
import GuruTable from "../components/guru/GuruTable";
import ImportGuruModal from "../components/guru/ImportGuruModal";
import guruService from "../services/api/guru";
import { setData } from "../store/features/teacher-slice";
const { Search } = Input;

function Guru() {
  const dispatch = useDispatch();
  const detailModalRef = useRef();
  const importModalRef = useRef();
  const dataGuru = useSelector((state) => state.guru); // Data guru from store
  const [loading, setLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: 10,
    current: 1,
  });

  const tableChangeHandler = (pagination, filters, sorter) => {
    const params = {};
    params.offset = (pagination.current - 1) * pagination.pageSize;
    params.limit = pagination.pageSize;
    params.current = pagination.current;
    if (sorter.order && sorter.field) {
      params.sortField = sorter.field;
      params.sortOrder = sorter.order.replace("end", ""); // ascend to asc, descend to desc
    }
    setQueryParams((queryParams) => ({ ...queryParams, ...params }));
  };

  const getDataGuru = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        let res = await guruService.getDataGuru(params);
        dispatch(
          setData({
            results: res.data.results,
            results_found: res.data.results_found,
          })
        );
      } catch (error) {
        message.error("Tidak dapat memuat data");
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const searchHandler = (keyword) => {
    setQueryParams((queryParams) => ({
      ...queryParams,
      current: 1,
      offset: 0,
      search: keyword,
    }));
  };

  const openDetailModalHandler = (guruId) => {
    if (detailModalRef) {
      detailModalRef.current.setGuruId(guruId);
      detailModalRef.current.setVisible(true);
    }
  };

  const openImportModalHandler = () => {
    if (importModalRef) importModalRef.current.setVisible(true);
  };

  const successUpdateHandler = () => {
    detailModalRef.current.setVisible(false);
    getDataGuru(queryParams);
  };

  const successImportHandler = () => {
    importModalRef.current.setVisible(false);
    getDataGuru(queryParams);
  };

  useEffect(() => {
    getDataGuru(queryParams);
    console.log(dataGuru);
    return () => {};
  }, [getDataGuru, queryParams]);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Data Guru"
          >
            <Row>
              <Col span={8} style={{ padding: "1rem" }}>
                <Space size={10}>
                  <Button type="primary" ghost icon={<PlusSquareOutlined />}>
                    Input Data
                  </Button>
                  <Button
                    ghost
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={openImportModalHandler}
                  >
                    Import Data
                  </Button>
                </Space>
              </Col>
              <Col
                span={8}
                offset={8}
                style={{
                  padding: "1rem",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Search
                  placeholder="input search text"
                  style={{ width: 200 }}
                  onSearch={(val) => searchHandler(val)}
                />
              </Col>
            </Row>
            <GuruTable
              dataSource={dataGuru.data.results}
              loading={loading}
              onClickDetail={openDetailModalHandler}
              onTableChange={tableChangeHandler}
              pagination={{
                current: queryParams.current,
                pageSize: queryParams.limit,
                total: dataGuru.data.results_found,
              }}
            />
          </Card>
        </Col>
      </Row>
      <GuruDetailModal
        ref={detailModalRef}
        onSuccessUpdate={successUpdateHandler}
      />
      <ImportGuruModal
        ref={importModalRef}
        onSuccessImport={successImportHandler}
      />
    </>
  );
}

export default Guru;
