import {
  ArrowLeftOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import CardLoadingSoal from "../components/loaders/CardLoadingSoal";
import CardSoal from "../components/paket-ujian/CardSoal";
import SoalSelector from "../components/paket-ujian/SoalSelector";
import ujianService from "../services/api/ujian";
import { setDataRombel } from "../store/features/paket-ujian";

const { Title } = Typography;

function PaketUjianEditor() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: ujianUUID } = useParams();
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );
  const { dataRombel } = useSelector((state) => state.paketUjian);
  const { semester_id } = useSelector((state) => state.appSetting);
  const user = useSelector((state) => state.authUser.user);
  const soalSelectorRef = useRef();
  const formRef = useRef();
  const [dataUjian, setDataUjian] = useState([]);
  const [soalUjian, setSoalUjian] = useState([]);
  const [soalIds, setSoalIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ujianId, setUjianId] = useState(null);

  const addSoalHandler = (newSoal) => {
    if (soalIds.includes(newSoal.id)) return;
    setSoalUjian((soal) => [...soal, newSoal]);
  };

  const removeSoalHandler = (soalId) => {
    setSoalUjian((soal) => soal.filter((s) => s.id !== soalId));
  };

  const validateForm = () => {
    let validateSoal = soalIds && soalIds.length > 0;
    if (!validateSoal) message.error("Harus ada soal!");
    return validateSoal;
  };

  const submitHandler = async (values) => {
    if (!validateForm()) return;
    try {
      let payload = {
        nama_ujian: values.nama_ujian,
        jenis_ujian_id: 3,
        tgl_ujian: moment(values.tgl_ujian).format("YYYY-MM-DD"),
        rombel_ujian: dataRombel
          .filter((rombel, index) => values.rombel_ujian.includes(index))
          .map((rombel) => ({
            rombongan_belajar_id: rombel.rombongan_belajar_id,
            jurusan_id: rombel.jurusan_id,
            jurusan_induk_id: rombel.jurusan_induk_id,
            tingkat: rombel.tingkat,
          })),
        soal_json: soalIds,
        mata_pelajaran_id: selectedMapel.mapel_id,
        jumlah_soal: values.jumlah_soal,
        waktu_mulai: moment(values.waktu_ujian[0], "HH:mm:ss").format(
          "HH:mm:ss"
        ),
        waktu_akhir: moment(values.waktu_ujian[1], "HH:mm:ss").format(
          "HH:mm:ss"
        ),
      };
      // return console.log(payload);
      console.log(payload);
      if (ujianUUID && ujianId) {
        await ujianService.updateUjian(payload, ujianId); // Update ujian
        message.success("Ujian berhasil diupdate");
      } else {
        await ujianService.postUjian(payload); // Create new ujian
        message.success("Ujian berhasil dibuat");
      }
      history.push("/paket-ujian");
    } catch (error) {
      console.log(error.response);
      message.error("Terjadi kesalahan.");
    }
  };

  const fetchDataUjian = useCallback(async () => {
    try {
      setLoading(true);
      let res = await ujianService.getUjianByUUID(ujianUUID);
      let soalRes = await ujianService.getSoalByIds(
        JSON.parse(res.data.result.soal_json)
      );
      // console.log(res);
      // console.log(soalRes);
      setDataUjian({
        nama_ujian: res.data.result.nama_ujian,
        jenis_ujian_id: res.data.result.jenis_ujian_id,
        tgl_ujian: moment(res.data.result.tgl_ujian),
        // rombel_ujian: dataRombel
        //   .map((rombel, index) => ({
        //     ...rombel,
        //     value: index,
        //   }))
        //   .filter((rombel) => {
        //     let data = res.data.result.rombel_ujian;
        //     return data.some(
        //       (rmb) =>
        //         rmb.rombongan_belajar_id === rombel.rombongan_belajar_id &&
        //         rmb.jurusan_id === rombel.jurusan_id &&
        //         rmb.jurusan_induk_id === rombel.jurusan_induk_id &&
        //         rmb.tingkat === rombel.tingkat
        //     );
        //   }),
        jumlah_soal: res.data.result.jml_pg_tampil,
        waktu_ujian: [
          moment(res.data.result.waktu_mulai, "HH:mm:ss"),
          moment(res.data.result.waktu_akhir, "HH:mm:ss"),
        ],
        is_used: res.data.result.is_used,
      });
      setUjianId(res.data.result.id);
      setSoalUjian(soalRes.data.results);
    } catch (error) {
      message.error("Tidak dapat memuat halaman");
      history.replace("/paket-ujian");
    } finally {
      setLoading(false);
    }
  }, [ujianUUID, history]);

  const fetchDataRombel = useCallback(async () => {
    try {
      const params = {
        mapel_id: selectedMapel.mapel_id,
        tingkat: selectedMapel.tingkat,
        semester_id: semester_id,
        guru_id: user.guru_id,
      };
      let res = await ujianService.getDataRombel(params);
      dispatch(setDataRombel(res.data.results));
    } catch {}
  }, [semester_id, user, selectedMapel, dispatch]);

  useEffect(() => {
    if (formRef.current) formRef.current.setFieldsValue(dataUjian);
    console.log(dataUjian);
  }, [dataUjian]);

  useEffect(() => {
    setSoalIds(soalUjian.map((soal) => soal.id));
  }, [soalUjian]);

  useEffect(() => {
    if (ujianUUID) fetchDataUjian();
  }, [ujianUUID, fetchDataUjian]);

  useEffect(() => {
    fetchDataRombel();
  }, [fetchDataRombel]);

  useEffect(() => {
    if (!selectedMapel || !selectedMapel.mapel_id || !selectedMapel.tingkat) {
      message.warning("Pilih mata pelajaran");
      history.replace("/paket-ujian");
    }
  }, [selectedMapel, history]);

  return (
    <>
      <Card>
        <Row>
          <Col>
            <Title level={5} style={{ marginBottom: "1rem" }}>
              Data Paket Ujian
            </Title>
          </Col>
        </Row>
        <Form
          ref={formRef}
          className="paket-ujian-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          layout="horizontal"
          onFinish={submitHandler}
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} harus diisi.",
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item label="Jenis Ujian" name={"jenis_ujian_id"}>
                <Select
                  defaultValue={"3"}
                  style={{ width: 200 }}
                  // disabled
                >
                  <Select.Option value={"3"}>
                    Penilaian Akhir Semester
                  </Select.Option>
                  <Select.Option value={"1"}>Ujian Sekolah</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"nama_ujian"}
                label="Nama Paket Ujian"
                rules={[{ required: true }]}
                initialValue={
                  selectedMapel.nama_mapel +
                  " - " +
                  (selectedMapel.tingkat === 1
                    ? "X"
                    : selectedMapel.tingkat === 2
                    ? "XI"
                    : selectedMapel.tingkat === 3
                    ? "XII"
                    : "-")
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"tgl_ujian"}
                label="Tanggal Ujian"
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} req>
            <Col span={8}>
              <Form.Item
                name={"waktu_ujian"}
                label="Waktu Ujian"
                rules={[{ required: true }]}
              >
                <TimePicker.RangePicker format={"HH:mm"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"rombel_ujian"}
                label="Kelas Ujian"
                rules={[{ required: true }]}
              >
                <Select mode={"multiple"} style={{ width: 200 }}>
                  {dataRombel.map((rombel, index) => (
                    <Select.Option key={index} value={index}>
                      {rombel.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"jumlah_soal"}
                label="Jumlah Soal Tampil"
                rules={[{ required: true }]}
                initialValue={40}
              >
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            align="bottom"
            justify="end"
          >
            <Col>
              <Space>
                <NavLink to={"/paket-ujian"}>
                  <Button
                    className="bg-success"
                    style={{ background: "#91d5ff" }}
                  >
                    <ArrowLeftOutlined className="bg-success" />
                    Kembali
                  </Button>
                </NavLink>
                {dataUjian.is_used === 1 ? (
                  ""
                ) : (
                  <Button
                    type="default"
                    style={{ background: "#73d13d" }}
                    htmlType={"submit"}
                  >
                    <SaveOutlined /> Simpan
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row justify="space-between" align="middle" style={{ padding: "1rem 0" }}>
        <Col>
          <Title level={2}>
            <UnorderedListOutlined style={{ marginRight: "0.5rem" }} />
            {soalUjian.length} Soal
          </Title>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            onClick={() => soalSelectorRef.current.setVisible(true)}
          >
            Tambahkan Soal
          </Button>
        </Col>
      </Row>
      {!loading ? (
        soalUjian.map((soal, index) => (
          <CardSoal
            key={index}
            soalIndex={++index}
            onRemove={removeSoalHandler}
            soal={soal}
          />
        ))
      ) : (
        <CardLoadingSoal />
      )}
      <SoalSelector
        ref={soalSelectorRef}
        selectedIds={soalIds}
        onAddSoal={addSoalHandler}
      />
    </>
  );
}

export default PaketUjianEditor;
