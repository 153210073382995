import axios from "axios";
import { API_URL } from "../../utils/config";

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const getAgama = async () => {
  let endpoint = `${API_URL}/get-reference/agama`;
  return await axios.get(endpoint, headers);
};

const getJenisPtk = async () => {
  let endpoint = `${API_URL}/get-reference/jenis-ptk`;
  return await axios.get(endpoint, headers);
};

const getStatusKepegawaian = async () => {
  let endpoint = `${API_URL}/get-reference/status-kepegawaian`;
  return await axios.get(endpoint, headers);
};

const referenceService = { getAgama, getJenisPtk, getStatusKepegawaian };

export default referenceService;
