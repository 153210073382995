import { Button, Card, Col, Row, Space, Input } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useSelector } from "react-redux";
import RombelCreateForm from "./components/RombelCreateForm";
import RombelTable from "./components/RombelTable";

function Rombel() {
  const appSetting = useSelector((state) => state.appSetting);
  const rombel = useSelector((state) => state.rombel);
  const rombelCreateFromRef = useRef();
  const rombelTableRef = useRef();

  const openRombelCreateForm = () => {
    rombelCreateFromRef.current.setVisible(true);
    rombelCreateFromRef.current.setup();
  };

  const onSearchTable = (e) => {
    rombelTableRef.current.onSearchTable(e.target.value);
  };

  const getDataRombel = () => {
    rombelTableRef.current.getDataRombel({
      ...rombel.pagination,
      sekolah_id: appSetting.sekolah_id,
      semester_id: appSetting.semester_id,
    });
  };

  return (
    <div className="layout-content">
      <Row className="rowgap-vbox">
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Data Rombel"
          >
            <Row>
              <Col span={14} style={{ padding: "1rem" }}>
                <Space direction="horizontal" style={{ width: "100%" }}>
                  <Button
                    type="primary"
                    ghost
                    icon={<PlusSquareOutlined />}
                    onClick={openRombelCreateForm}
                    disabled={!appSetting?.sekolah_id ? true : false}
                  >
                    Input Data
                  </Button>
                </Space>
              </Col>
              <Col
                span={8}
                offset={2}
                style={{
                  padding: "1rem",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Input.Search
                  placeholder="input search text"
                  style={{ width: 200 }}
                  onKeyUp={onSearchTable}
                />
              </Col>
            </Row>
            <div className="table-responsive">
              <RombelTable ref={rombelTableRef} />
            </div>
          </Card>
        </Col>
      </Row>
      <RombelCreateForm ref={rombelCreateFromRef} onSuccess={getDataRombel} />
    </div>
  );
}

export default Rombel;
