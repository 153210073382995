import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, message, Pagination, Row, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { addStyles } from "react-mathquill";
import { useDispatch, useSelector } from "react-redux";
import CardLoadingSoal from "../components/loaders/CardLoadingSoal";
import SelectMapel from "../components/settings/SelectMapel";
import SoalEditorModal from "../components/soal-editor/SoalEditorModal";
import CardSoal from "../components/soal/CardSoal";
import soalService from "../services/api/soal";
import { setData as setDataSoal, setPagination } from "../store/features/soal";
import { setEditorVisible } from "../store/features/soal-editor";

addStyles();

export default function Soal() {
  const dispatch = useDispatch();
  const { data: dataSoal, pagination } = useSelector((state) => state.soal);
  const [loading, setLoading] = useState(false);
  const { visible: editorVisible } = useSelector((state) => state.soalEditor);
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );

  const fetchDataSoal = useCallback(
    async (signal = new AbortController().signal) => {
      setLoading(true);
      try {
        let params = {
          currentPage: pagination.currentPage,
          limit: pagination.pageSize,
          ...selectedMapel,
        };
        let res = await soalService.getDataSoal(params, { signal: signal });

        dispatch(
          setDataSoal({
            results: res.data.results,
            results_found: res.data.results_found,
          })
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          message.error("Tidak dapat memuat data.");
        }
      }
      setLoading(false);
    },
    [dispatch, pagination, selectedMapel]
  );

  const paginationChangeHandler = (page, pageSize) => {
    dispatch(setPagination({ currentPage: page, pageSize: pageSize }));
  };

  const successDeleteHandler = (id) => {
    dispatch(
      setDataSoal({
        results: dataSoal.results.filter((soal) => soal.id !== id),
        results_found: dataSoal.results_found - 1,
      })
    );
  };

  const openSoalEditor = () => {
    if (!selectedMapel || !selectedMapel.mapel_id || !selectedMapel.tingkat) {
      message.warning("Pilih mata pelajaran terlebih dahulu!");
    } else {
      dispatch(setEditorVisible(true));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDataSoal(controller.signal);

    return () => controller.abort();
  }, [fetchDataSoal, pagination]);

  return (
    <>
      {!editorVisible ? (
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 18 }} xl={{ span: 16 }}>
            <Row
              justify="space-between"
              align="middle"
            >
              <Col>
                <Typography.Title level={3}>Data Soal</Typography.Title>
                <div style={{ paddingBottom: "1rem" }}>
                  <SelectMapel />
                </div>
              </Col>
              <Col>
                <Button
                  size="large"
                  onClick={openSoalEditor}
                  style={{ float: "right" }}
                  type="primary"
                  icon={<PlusCircleFilled />}
                >
                  Tambah Soal
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="">Jumlah Soal : {dataSoal.results.length}</Button>
              </Col>
            </Row>
            {loading && <CardLoadingSoal />}
            {!loading && dataSoal.results.length > 0 && (
              <>
                <Pagination
                  style={{ margin: "0.5rem 0 1rem" }}
                  current={pagination.currentPage}
                  total={dataSoal.results_found}
                  onChange={paginationChangeHandler}
                />
                {dataSoal.results.map((soal, index) => {
                  return (
                    <CardSoal
                      soal={soal}
                      key={index}
                      onSuccessDelete={successDeleteHandler}
                    />
                  );
                })}
                <Pagination
                  style={{ margin: "0.5rem 0 1rem" }}
                  current={pagination.currentPage}
                  total={dataSoal.results_found}
                  pageSize={pagination.pageSize}
                  onChange={paginationChangeHandler}
                />
              </>
            )}
          </Col>
        </Row>
      ) : (
        <SoalEditorModal onSuccess={fetchDataSoal} visible={editorVisible} />
      )}
    </>
  );
}
