import axios from "axios";
import qs from "qs";
import { API_URL } from "../../utils/config";

const getHeaders = () => {
  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

const getRemedialByUUID = async (uuid) => {
  let endpoint = `${API_URL}/remedial/get-by-uuid/${uuid}`;
  return await axios.get(endpoint, { ...getHeaders() });
};

const getSoalByIds = async (soalId) => {
  let endpoint = `${API_URL}/remedial/get-soal-array`;
  return await axios.post(endpoint, { soal_id: soalId }, { ...getHeaders() });
};

const postRemedial = async (payload) => {
  let endpoint = `${API_URL}/remedial`;
  return await axios.post(endpoint, payload, { ...getHeaders() });
};

const updateRemedial = async (payload, id) => {
  let endpoint = `${API_URL}/remedial/${id}`;
  return await axios.put(endpoint, payload, { ...getHeaders() });
};

const updateStatusRemedial = async (payload, id) => {
  let endpoint = `${API_URL}/remedial/update-status/${id}`;
  return await axios.put(endpoint, payload, { ...getHeaders() });
};

const getCurrentDataUjian = async (params, config) => {
  let endpoint = `${API_URL}/data-ujian?${qs.stringify(params)}`;
  return await axios.get(endpoint, { ...getHeaders(), ...config });
};

const ujianService = {
  getSoalByIds,
  postRemedial,
  getRemedialByUUID,
  updateRemedial,
  updateStatusRemedial,
  getCurrentDataUjian,
};

export default ujianService;
