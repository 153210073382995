import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { openNotificationWithIcon } from "../components/notification/customNotification";
import resetPasswordService from "../services/auth/reset-password";
import { API_URL } from "../utils/config";

function ResetPassword() {
  const [formData, setFormData] = useState({
    email: '',
    token: '',
    password: '',
    password_confirmation: ''
  });

  const [error, setError] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const formRef = useRef();
  const history = useHistory();

  const onFinish = async (values) => {
    setLoadingButton(true);
    try {
      const data  = {...formData, ...values}
      await resetPasswordService.resetPassword(data);
      openNotificationWithIcon("Berhasil", "Password Berhasil diubah !", <CheckCircleOutlined style={{ color: "#52c41a" }} />);
      setError({});
      history.push('/login');
    } catch (error) {
      if(error.response.status === 422) {
        openNotificationWithIcon("Peringatan", "Silahkan cek kembali formulir anda", <WarningOutlined style={{ color: "yellow" }} />)
        setError(error.response?.data?.errors)
      }
      else if(error.response.status === 404)
        openNotificationWithIcon("Failed", "Email tidak dapat ditemukan !", <WarningOutlined style={{ color: '#ff0000' }} />)
      else if(error.response.status === 503)
        openNotificationWithIcon("Failed", "Token Expired !", <WarningOutlined style={{ color: '#ff0000' }} />)
      else 
        openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />)
    } finally {
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFormData({
      ...formData,
      ...params
    });
  }, [])

  useEffect(() => {
    if(formRef.current) formRef.current.setFieldsValue(formData)
  }, [formData])

  const formItemProps = (key) => {
    return {
      name: key,
      validateStatus: error[key] ? "error" : null,
      help: error?.[key]?.[0],
    }
  };

  return (
    <Layout className="layout-default layout-signin" style={{ minHeight: "100vh" }}>
        <Layout.Content className="signin">
          <Row gutter={[24, 0]} style={{ justifyContent: "center" }}>
            <Col span={8}>
              <Card>
                <Typography.Title level={3}>Reset Password</Typography.Title>
                <Form
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 19 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  autoComplete="off"
                  ref={formRef}
                >
                  <Form.Item label="Email" {...formItemProps("email")}>
                    <Input type="email" placeholder="example@mail.com" value={formData.email}/>
                  </Form.Item>
                  <Form.Item label="Password" {...formItemProps("password")}>
                    <Input type="password" placeholder="password" value={formData.password}/>
                  </Form.Item>
                  <Form.Item label="Konfirmasi" {...formItemProps("password_confirmation")}>
                    <Input type="password" placeholder="Masukan password yang sama" value={formData.password_confirmation}/>
                  </Form.Item>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="primary" htmlType="submit" loading={loadingButton}>Reset</Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
  )
}

export default ResetPassword;