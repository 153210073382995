import { Button, Space, Table } from "antd";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import rombelService from "../../../services/api/rombel";
import {
  setData as setDataRombel,
  setPagination as setPaginationRombel,
} from "../../../store/features/rombel";
import DataAnggotaRombelModal from "./DataAnggotaRombelModal";
import PembelajaranModal from "./PembelajaranModal";

const RombelTable = forwardRef((props, ref) => {
  // Global state
  const appSetting = useSelector((state) => state.appSetting);
  const rombel = useSelector((state) => state.rombel);
  const dispatch = useDispatch();

  // Local State
  const [loading, setLoading] = useState(false);
  const dataAnggotaRombelModalRef = useRef();
  const pembelajaranModalRef = useRef();

  // Methods
  const getDataRombel = async (
    params = {},
    signal = new AbortController().signal
  ) => {
    setLoading(true);
    try {
      const res = await rombelService.getDataRombel(params);
      dispatch(setDataRombel(res.data));
      dispatch(setPaginationRombel(params));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    const params = {};
    params.offset =
      pagination.current === 1
        ? 0
        : (pagination.current - 1) * pagination.pageSize;
    params.limit = pagination.pageSize;
    params.current = pagination.current;
    if (typeof sorter.order !== "undefined") {
      params.sortField = sorter.field;
      params.sortOrder = sorter.order.replace("end", "");
    }
    getDataRombel({ ...rombel.pagination, ...params });
  };

  const onSearchTable = (keyword) =>
    getDataRombel({
      ...rombel.pagination,
      ...appSetting,
      search: keyword,
      limit: 10,
      offset: 0,
    });

  const openDataAnggotaRombelModal = (record) => {
    dataAnggotaRombelModalRef.current.setVisible(true);
    dataAnggotaRombelModalRef.current.getDataAnggotaRombel(record.id);
  };

  const openDataPembelajaranModal = (record) => {
    pembelajaranModalRef.current.setVisible(true);
    pembelajaranModalRef.current.setup(record);
  };

  useImperativeHandle(ref, () => ({
    onSearchTable(keyword) {
      onSearchTable(keyword);
    },
    getDataRombel(params, signal) {
      getDataRombel(params, signal);
    },
  }));

  useEffect(() => {
    console.log(appSetting);
    if (appSetting?.sekolah_id) {
      getDataRombel({
        ...rombel.pagination,
        sekolah_id: appSetting.sekolah_id,
        semester_id: appSetting.semester_id,
        limit: 10,
        offset: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (appSetting?.sekolah_id) {
      getDataRombel({
        ...rombel.pagination,
        sekolah_id: appSetting.sekolah_id,
        semester_id: appSetting.semester_id,
        limit: 10,
        offset: 0,
      });
    }
  }, [appSetting]);

  const columns = [
    {
      title: "Nama Rombel",
      dataIndex: ["nama"],
      sorter: true,
    },
    {
      title: "Semester",
      dataIndex: ["semester", "nama"],
      sorter: true,
    },
    {
      title: "Jurusan SP",
      dataIndex: ["jurusan_s_p", "nama_jurusan_sp"],
      sorter: true,
    },
    {
      title: "Kurikulum",
      dataIndex: ["kurikulum", "nama_kurikulum"],
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Space direction="horizontal">
          <Button
            type="primary"
            ghost
            onClick={(_) => openDataAnggotaRombelModal(record)}
          >
            Siswa
          </Button>
          <Button
            type="primary"
            ghost
            onClick={(_) => openDataPembelajaranModal(record)}
          >
            Mapel
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={rombel.data.results}
        pagination={{
          current: rombel.pagination.current,
          pageSize: rombel.pagination.limit,
          total: rombel.data.results_found,
        }}
        loading={loading}
        onChange={onChangeTable}
      />
      <DataAnggotaRombelModal ref={dataAnggotaRombelModalRef} />
      <PembelajaranModal ref={pembelajaranModalRef} />
    </>
  );
});

export default RombelTable;
