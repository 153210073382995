import { InboxOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import guruService from "../../services/api/guru";
import { API_URL } from "../../utils/config";

const ImportGuruModal = forwardRef((props, ref) => {
  const { onSuccessImport } = props;
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ visible: false });

  useImperativeHandle(ref, () => ({
    setVisible: (visible) => setVisible(visible),
  }));

  const uploadHandler = async () => {
    let hideLoadingToast = message.loading("Mengimport file...", 0);
    setLoading(true);
    setError({ visible: false });
    try {
      let formData = new FormData();
      formData.append("file", fileList[0]);
      let res = await guruService.importGuru(formData);
      console.log(res.data);
      message.success(res.data.message);
      onSuccessImport();
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error.response.data);
      errorHandler(error.response);
    } finally {
      hideLoadingToast();
      setLoading(false);
    }
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    fileList,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList((files) => [...files, file]);
      return false;
    },
  };

  const errorHandler = (error) => {
    let visible = true;
    let message = "Terjadi kesalahan";
    let description = null;
    // Check if error is unprocessable content error
    if (error.status === 422) {
      message = error.data.message ?? message;
      description = (
        // Show lists of unprocessable contents
        <ul style={{ marginBottom: 0 }}>
          {Object.values(error.data.errors).map((error, index) => (
            <li key={index}>{error[0]}</li>
          ))}
        </ul>
      );
    }
    setError({ visible, message, description });
  };

  return (
    <Modal
      title="Import data guru"
      visible={visible}
      onOk={uploadHandler}
      onCancel={() => setVisible(false)}
      width={"50rem"}
      okText={"Import data"}
      cancelText={"Batal"}
      okButtonProps={{ disabled: fileList.length <= 0 || loading }}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Upload file" key="1">
          {error.visible && (
            <Alert
              message={error.message}
              description={error.description}
              type="error"
              closable
              style={{ marginBottom: 10 }}
              onClick={() => setError({ visible: false })}
            />
          )}
          <Upload.Dragger
            {...draggerProps}
            style={{ display: fileList.length > 0 ? "none" : "block" }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Tarik file ke sini</p>
            <p className="ant-upload-hint">Atau, klik disini</p>
          </Upload.Dragger>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Panduan Import" key="2">
          <Row align="middle" justify="center">
            <Col style={{ padding: "2rem 0" }}>
              <Space direction="vertical" align="center">
                <Title level={5}>Langkah-langkah import data</Title>
                <ol>
                  <li>
                    <div>Unduh template excel dengan klik tombol dibawah</div>
                  </li>
                  <li>Isi file excel dengan data yang ingin diimport</li>
                  <li>Pastikan data yang diisi sudah benar</li>
                  <li>Upload file yang telah diisi</li>
                </ol>
                <a href={`${API_URL}/template/import-guru`}>
                  <Button>Unduh template</Button>
                </a>
              </Space>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
});

export default ImportGuruModal;
