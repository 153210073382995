import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormCreate from "../components/sekolah/FormCreate";
import FormDetail from "../components/sekolah/FormDetail";
import FormAddJurusan from "../components/sekolah/FormJurusan";
import API from "../services/api/sekolah";
import { setData, setPagination } from "../store/features/sekolah";


function Sekolah() {
  const { Search } = Input;
  const [ loading, setLoading ] = useState(false);
  const formCreateRef = useRef()
  const formDetailRef = useRef()
  const formAddJurusan = useRef()
  const sekolah = useSelector(state => state.sekolah)
  const dispatch = useDispatch()

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'nama',
      sorter: true,
    },
    {
      title: 'NPSN',
      dataIndex: 'npsn',
      sorter: true,
    },
    {
      title: 'NSS',
      dataIndex: 'nss',
      sorter: true,
    },
    {
      title: 'Kecamatan',
      dataIndex: 'kecamatan',
      sorter: true,
    },
    {
      title: 'Kabupaten',
      dataIndex: 'kabupaten',
      sorter: true,
    },
    {
      title: 'Provinsi',
      dataIndex: 'provinsi',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space direction="horizontal">
          <Button type="primary" ghost onClick={() => handleDetail(record)}>Detail</Button>
          <Button type="primary" ghost onClick={() => handleJurusan(record)}>Jurusan</Button>
        </Space>
      ),
    }
  ];

  const tableChangeHandler = (pagination, filters, sorter) => {
    const params = {};
    params.offset = (pagination.current - 1) * pagination.pageSize;
    params.limit = pagination.pageSize;
    params.current = pagination.current;
    if (sorter.order && sorter.field) {
      params.sortField = sorter.field;
      params.sortOrder = sorter.order.replace("end", "");
    }
    getDataSekolah({...sekolah.pagination,...params});
  };

  const getDataSekolah = async (params = {}, signal = (new AbortController()).signal) => {
    setLoading(true)
    try {
      const res = await API.getDataSekolah(params, signal);
      dispatch(setData(res.data))
      dispatch(setPagination(params))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onSearch = (e) => {
    if(e.keyCode === 13) {
      getDataSekolah({...sekolah.pagination, search: e.target.value, current: 1, offset: 0});
    }
  }

  const handleDetail = (record) => {
    formDetailRef.current.setVisible(true)
    formDetailRef.current.setup(record)
  }
  
  const handleJurusan = (record) => {
    formAddJurusan.current.setVisible(true)
    formAddJurusan.current.setDetailDataSekolah(record)
  }

  const formCreateHandler = () => {
    formCreateRef.current.setVisible(true);
    formCreateRef.current.setup();
  }

  useEffect(() => {
    getDataSekolah({offset: 0, limit: 10}, sekolah.controller.signal)
    return () => { sekolah.controller.abort() }
  }, []);

  return (
    <div className="layout-content">
      <Row className="rowgap-vbox">
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Data Sekolah"
          >
            <Row>
              <Col span={8} style={{ padding: "1rem" }}>
                <Button
                  type="primary"
                  ghost
                  icon={<PlusSquareOutlined />}
                  onClick={formCreateHandler}
                >
                  Tambah
                </Button>
              </Col>
              <Col
                span={8}
                offset={8}
                style={{
                  padding: "1rem",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Search
                  placeholder="input search text"
                  style={{ width: 200 }}
                  onSearch={(val) => onSearch(val)}
                />
              </Col>
            </Row>
            <div className="table-responsive">
              <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={sekolah.data.results}
                pagination={{ 
                  current: sekolah.pagination.current,
                  pageSize: sekolah.pagination.limit,
                  total: sekolah.data.results_found,
                }}
                loading={loading}
                onChange={tableChangeHandler}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <FormCreate
        ref={formCreateRef}
        getDataSekolah={() => getDataSekolah(sekolah.pagination)}
      />
      <FormDetail
        ref={formDetailRef}
        getDataSekolah={() => getDataSekolah(sekolah.pagination)}
      />
      <FormAddJurusan
        ref={formAddJurusan}
      />
    </div>
  );
}

export default Sekolah;
