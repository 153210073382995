import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from 'qs';

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const jurusanSpService = {
  getDataJurusanSP: async (params, signal) => {
    const endpoint = `${API_URL}/jurusan-sp?${qs.stringify(params)}`;
    return await axios.get(endpoint, { signal, ...headers });
  },
  postDataJurusanSP: async (data) => {
    const endpoint = `${API_URL}/jurusan-sp`;
    return await axios.post(endpoint, data, headers);
  },
  deleteDataJurusanSPByID: async (id) => {
    const endpoint = `${API_URL}/jurusan-sp/${id}`;
    return await axios.post(endpoint, { _method: "DELETE" }, headers);
  }
}

export default jurusanSpService;