import { createSlice } from "@reduxjs/toolkit";

const ujian = createSlice({
  name: "ujian",
  initialState: {
    data: {
      results: [],
      results_found: 0,
    },
    selectedData: {},
    pagination: {
      limit: 10,
      offset: 0,
      current: 1,
    },
    controller: new AbortController(),
  },
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setSelectedData: (state, action) => {
      return {
        ...state,
        selectedData: action.payload,
      };
    },
    setPagination: (state, action) => {
      return {
        ...state,
        pagination: action.payload,
      };
    },
  },
});

export const { setData, setSelectedData, setPagination } = ujian.actions;
export default ujian.reducer;
