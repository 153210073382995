import { combineReducers } from "@reduxjs/toolkit";
import appSettingReducer from "./features/app-setting";
import authUserReducer from "./features/auth-user";
import guruReducer from "./features/guru";
import jurusanReducer from "./features/jurusan";
import jurusanSpReducer from "./features/jurusan-sp";
import kelompokReducer from "./features/kelompok";
import kurikulumReducer from "./features/kurikulum";
import mataPelajaranReducer from "./features/mata-pelajaran";
import paketUjianReducer from "./features/paket-ujian";
import pembelajaranReducer from "./features/pembelajaran";
import pesertaDidikReducer from "./features/peserta-didik";
import refWilayahReducer from "./features/ref-wilayah";
import rombelReducer from "./features/rombel";
import sekolahReducer from "./features/sekolah";
import semesterReducer from "./features/semester";
import soalReducer from "./features/soal";
import soalEditorReducer from "./features/soal-editor";
import soalSelectorReducer from "./features/soal-selector";
import ujianReducer from "./features/ujian-slice";

export default combineReducers({
  sekolah: sekolahReducer,
  pesertaDidik: pesertaDidikReducer,
  refWilayah: refWilayahReducer,
  guru: guruReducer,
  jurusan: jurusanReducer,
  kurikulum: kurikulumReducer,
  rombel: rombelReducer,
  jurusanSp: jurusanSpReducer,
  semester: semesterReducer,
  pembelajaran: pembelajaranReducer,
  mataPelajaran: mataPelajaranReducer,
  kelompok: kelompokReducer,
  soal: soalReducer,
  authUser: authUserReducer,
  appSetting: appSettingReducer,
  soalEditor: soalEditorReducer,
  soalSelector: soalSelectorReducer,
  paketUjian: paketUjianReducer,
  ujian: ujianReducer,
});
