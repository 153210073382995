import {
  BoldOutlined,
  FontColorsOutlined,
  FunctionOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Row, Space, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openMathEditor,
  setFocusRange,
} from "../../store/features/soal-editor";

export default function EditorToolbar(props) {
  const dispatch = useDispatch();
  const { focusRange } = useSelector((state) => state.soalEditor);

  const openMathEditorHandler = () => {
    dispatch(setFocusRange());
    dispatch(openMathEditor());
  };

  const bold = () => {
    document.execCommand("bold");
  };

  const italic = () => {
    document.execCommand("italic");
  };

  const underline = () => {
    document.execCommand("underline");
  };

  const strikeThrough = () => {
    document.execCommand("strikeThrough");
  };

  return (
    <div className="soal-editor-toolbar">
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", padding: "1rem" }}
      >
        {focusRange && (
          <Col xs={{ span: 23 }} sm={{ span: 20 }} md={{ span: 18 }}>
            <Tooltip title={"Warna teks"} placement="bottom">
              <Button icon={<FontColorsOutlined />} />
            </Tooltip>
            <Divider type={"vertical"} />
            <Space>
              <Tooltip title={"Miringkan teks"} placement="bottom">
                <Button icon={<BoldOutlined />} onClick={bold} />
              </Tooltip>
              <Tooltip title={"Miringkan teks"} placement="bottom">
                <Button icon={<ItalicOutlined />} onClick={italic} />
              </Tooltip>
              <Tooltip title={"Garis bawahi teks"} placement="bottom">
                <Button icon={<UnderlineOutlined />} onClick={underline} />
              </Tooltip>
              <Tooltip title={"Coret teks"} placement="bottom">
                <Button
                  icon={<StrikethroughOutlined />}
                  onClick={strikeThrough}
                />
              </Tooltip>
            </Space>
            <Divider type={"vertical"} />
            <Tooltip title={"Sisipkan persamaan matematika"} placement="bottom">
              <Button
                icon={<FunctionOutlined />}
                onClick={openMathEditorHandler}
              >
                Sisipkan Persamaan
              </Button>
            </Tooltip>
            {/* <Button icon={<ClearOutlined/>} onClick={() => {}}>Kosongkan Soal</Button> */}
          </Col>
        )}
      </Row>
    </div>
  );
}
