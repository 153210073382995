import { PlusSquareOutlined } from "@ant-design/icons";
import { Modal, Table, Button } from "antd";
import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
import pembelajaranService from "../../../services/api/pembelajaran";
import PembelajaranCreateForm from "./PembelajaranCreateForm";

const PembelajaranModal = forwardRef((props, ref) => {
  // Global State
  // const pembelajaran = useSelector(state => state.pembelajaran)
  // const dispatch = useDispatch()
  // Local State
  const [detailSekolah, setDetailSekolah] = useState({});
  const [localPembelajaran, setLocalPembelajaran] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const PembelajaranCreateFormRef = useRef();

  const getDataPembelajaran = async (params = {}, signal = (new AbortController()).signal) => {
    const res = await pembelajaranService.getDataPembelajaran(params, signal)
    setLocalPembelajaran(res.data.results)
    setLoadingTable(false)
  }

  const PembelajaranCreateFormHandler = () => {
    PembelajaranCreateFormRef.current.setVisible(true)
    PembelajaranCreateFormRef.current.setup()
  }

  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean)
    },
    setup(data) {
      setLoadingTable(true)
      getDataPembelajaran({ rombongan_belajar_id: data.id })
      setDetailSekolah(data)
    }
  }))

  const columns = [
    {
      title: "Mata Pelajaran",
      dataIndex: ["mata_pelajaran", "nama"],
      sorter: true,
    },
    {
      title: "Nama Mata Pelajaran",
      dataIndex: ["nama_mata_pelajaran"],
      sorter: true,
    },
    {
      title: "Kelompok",
      dataIndex: ["kelompok", 'nama_kelompok'],
      sorter: true,
    },
    {
      title: "Semester",
      dataIndex: ["semester", 'nama'],
      sorter: true,
    },
    {
      title: 'Guru Pengajar',
      dataIndex: ["guru_pengajar", 'nama'],
      sorter: true,
    },
    {
      title: 'No Urut',
      dataIndex: ["no_urut"],
      sorter: true,
    },
    {
      title: 'KKM',
      dataIndex: ["kkm"],
      sorter: true,
    },
    {
      title: 'Rasio P',
      dataIndex: ["rasio_p"],
      sorter: true,
    },
    {
      title: 'Rasio K',
      dataIndex: ["rasio_k"],
      sorter: true,
    },
  ]

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
        width={1400}
      >
        <div style={{ diplay: "flex", justifyContent: "space-between" }}>
          <h3>Data Pembelajaran</h3>
        </div>
        <Button type="primary" ghost icon={<PlusSquareOutlined />} onClick={PembelajaranCreateFormHandler}>Tambah</Button>
        <Table
          loading={loadingTable}
          rowKey={(record) => record.id}
          dataSource={localPembelajaran}
          columns={columns}
        />
      </Modal>
      <PembelajaranCreateForm ref={PembelajaranCreateFormRef} detailSekolah={detailSekolah} getDataPembelajaran={() => setLoadingTable(true) || getDataPembelajaran({ rombongan_belajar_id: detailSekolah.id })} />
    </>
  )
})

export default PembelajaranModal;