import { createSlice } from "@reduxjs/toolkit";

const soalSelector = createSlice({
  name: "soal-selector",
  initialState: {
    data: {
      results: [],
      results_found: 0,
    },
    pagination: {
      pageSize: 10,
      currentPage: 1,
    },
  },
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setPagination: (state, action) => {
      return {
        ...state,
        pagination: action.payload,
      };
    },
  },
});

export const { setData, setSelectedData, setPagination } = soalSelector.actions;
export default soalSelector.reducer;
