import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from 'qs';

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const mataPelajaranService = {
  getDataMataPelajaran: async (params, signal) => {
    const endpoint = `${API_URL}/get-reference/mata-pelajaran?${qs.stringify(params)}`;
    return await axios.get(endpoint, { signal, ...headers });
  },
  postDataMataPelajaran: async (data) => {
    const endpoint = `${API_URL}/mata-pelajaran`;
    return await axios.post(endpoint, data, headers);
  }
}

export default mataPelajaranService;