import { Button, Table, Tag } from "antd";
import React from "react";

export default function GuruTable(props) {
  const { dataSource, loading, pagination, onClickDetail, onTableChange } =
    props;

  const columns = [
    {
      title: "#",
      dataIndex: "rowIndex",
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "nama",
      filterSearch: true,
      sorter: true,
      width: 100,
      fixed: "left",
    },
    {
      title: "NUPTK",
      dataIndex: "nuptk",
      sorter: true,
    },
    {
      title: "JK",
      dataIndex: "jenis_kelamin",
      sorter: true,
    },
    {
      title: "NIP",
      dataIndex: "nip",
      sorter: true,
    },
    {
      title: "TTL",
      key: "tempat_lahir",
      width: "10%",
      render: (data) => `${data.tempat_lahir}, ${data.tanggal_lahir}`,
    },
    {
      title: "Status Akun",
      key: "akun",
      dataIndex: "akun",
      width: "10%",
      render: (akun) => {
        if (!akun) return <Tag color="default">Tidak ada akun</Tag>;
        return akun.active === 1 ? (
          <Tag color="green">Aktif</Tag>
        ) : (
          <Tag color="red">Nonaktif</Tag>
        );
      },
    },
    {
      title: "Aksi",
      dataIndex: "id",
      fixed: "right",
      render: (id) => (
        <Button type="primary" ghost onClick={() => onClickDetail(id)}>
          Detail
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="table-responsive">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={dataSource}
          pagination={pagination}
          loading={loading}
          onChange={onTableChange}
        />
      </div>
    </>
  );
}
