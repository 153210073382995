import axios from "axios";
import { API_URL } from "../../utils/config";

const login = async (data) => {
  let endpoint = `${API_URL}/login`;
  return await axios.post(endpoint, data);
};

const authAdminService = {
  login
};

export default authAdminService;
