import { Button } from "antd";
import React from "react";
import { StaticMathField } from "react-mathquill";

export default function MathEditorButton(props) {
  const { label, latex, onClick: writeLatex } = props;
  return (
    <Button
      onClick={() => writeLatex(latex)}
      style={{
        width: 28,
        height: 28,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        padding: 25,
      }}
    >
      <StaticMathField style={{ cursor: "pointer", userSelect: "none" }}>
        {label ?? latex}
      </StaticMathField>
    </Button>
  );
}
