import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, message, Modal, Row, Space, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CreateKDModal from "../components/kompetensi-dasar/CreateKDModal";
import KDTable from "../components/kompetensi-dasar/KDTable";
import SelectMapel from "../components/settings/SelectMapel";
import kompetensiDasarService from "../services/api/kompetensiDasar";
const { Search } = Input;

export default function KompetensiDasar() {
  const [kompetensiDasar, setKompetensiDasar] = useState([]);
  const [filteredKompetensiDasar, setFilteredKompetensiDasar] = useState([]);
  const [loading, setLoading] = useState(false);
  const createKDModalRef = useRef();
  const mapel = useSelector((state) => state.appSetting.mapel.selected);

  const searchHandler = (val) => {
    setFilteredKompetensiDasar(
      kompetensiDasar.filter((kd) =>
        kd.kompetensi_dasar.toLowerCase().includes(val.toLowerCase())
      )
    );
  };

  const filterKD = async (params) => {
    setLoading(true);
    try {
      let res = await kompetensiDasarService.getDataKompetensiDasar(params);
      let kompetensiDasar = res.data.results.map((kd, index) => ({
        ...kd,
        rowIndex: ++index,
      }));
      setKompetensiDasar(kompetensiDasar);
      setFilteredKompetensiDasar(kompetensiDasar);
    } catch (error) {
      Modal.error({
        content: "Gagal mengambil data kompetensi dasar",
      });
    }
    setLoading(false);
  };

  const openCreateKDModal = () => {
    if (!createKDModalRef) return;
    if (!mapel.mapel_id)
      return message.warning("Pilih mata pelajaran terlebih dahulu!");
    createKDModalRef.current.setVisible(true);
  };

  useEffect(() => {
    if (mapel.mapel_id) filterKD({ mapel_id: mapel.mapel_id });
  }, [mapel]);

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={3}>Data Kompetensi Dasar</Typography.Title>
            <div style={{ paddingBottom: "1rem" }}>
              <SelectMapel />
            </div>
          </Col>
        </Row>
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title="List Kompetensi Dasar"
        >
          <Row>
            <Col span={8} style={{ padding: "1rem" }}>
              <Space size={10}>
                <Button
                  type="primary"
                  ghost
                  icon={<PlusSquareOutlined />}
                  onClick={openCreateKDModal}
                >
                  Input Data
                </Button>
              </Space>
            </Col>
            <Col
              span={8}
              offset={8}
              style={{
                padding: "1rem",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Search
                placeholder="input search text"
                style={{ width: 200 }}
                onChange={(e) => searchHandler(e.target.value)}
              />
            </Col>
          </Row>
          <KDTable
            kompetensiDasar={filteredKompetensiDasar}
            loading={loading}
          />
          <CreateKDModal
            ref={createKDModalRef}
            onSuccess={() => filterKD({ mapel_id: mapel.mapel_id })}
          />
        </Card>
      </Col>
    </Row>
  );
}
