import { createSlice } from "@reduxjs/toolkit";

const appSetting = createSlice({
  name: "app-setting",
  initialState: {
    sekolah_id: null,
    semester_id: null,
    mapel: {
      controller: new AbortController(),
      data: [],
      selected: {
        mapel_id: null,
        nama_mapel: null,
        tingkat: null,
      },
    },
  },
  reducers: {
    setSekolahId: (state, action) => {
      return {
        ...state,
        sekolah_id: action.payload,
      };
    },
    setSemesterId: (state, action) => {
      return {
        ...state,
        semester_id: action.payload,
      };
    },
    setDataMapel: (state, action) => {
      return {
        ...state,
        mapel: {
          ...state.mapel,
          data: action.payload,
        },
      };
    },
    setSelectedDataMapel: (state, action) => {
      return {
        ...state,
        mapel: {
          ...state.mapel,
          selected: action.payload,
        },
      };
    },
  },
});

export const {
  setSekolahId,
  setSemesterId,
  setDataMapel,
  setSelectedDataMapel,
} = appSetting.actions;
export default appSetting.reducer;
