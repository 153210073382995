import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from 'qs';

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const rombelService = {
  getDataRombel: async (params) => {
    const endpoint = `${API_URL}/rombel?${qs.stringify(params)}`;
    return await axios.get(endpoint, headers);
  },
  postDataRombel: async (data) => {
    const endpoint = `${API_URL}/rombel`;
    return await axios.post(endpoint, data, headers);
  },
}

export default rombelService;