/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import SidenavBrand from "../sidenav/SidenavBrand";
import SidenavMenus from "../sidenav/SidenavMenus";
// import SidenavFooter from "../molecules/sidenav/SidenavFooter";

function Sidenav(props) {
  const { color } = props;
  return (
    <>
      <SidenavBrand color={color} />
      <SidenavMenus color={color} />
      {/* <SidenavFooter color={color} /> */}
    </>
  );
}

export default Sidenav;
