import { Modal, Form, Button, Input, Select } from "antd";
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { openNotificationWithIcon } from "../../../components/notification/customNotification"
import { useDispatch, useSelector } from "react-redux"
import semesterService from "../../../services/api/semester";
import rombelService from "../../../services/api/rombel";
import jurusanSpService from "../../../services/api/jurusanSP";
import kurikulumService from "../../../services/api/kurikulum";
import { setData as setGlobalDataSemester } from "../../../store/features/semester";
import { setData as setGlobalDataJurusanSP } from "../../../store/features/jurusan-sp";
import { setData as setGlobalDataKurikulum } from "../../../store/features/kurikulum";
import { setData as setGlobalDataGuru } from "../../../store/features/guru";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import PembelajaranCreateForm from "./PembelajaranCreateForm";
import guruService from "../../../services/api/guru";

const RombelCreateForm = forwardRef((props, ref) => {
  // Component
  const { Option } = Select;
  // Global State
  const appSetting = useSelector(state => state.appSetting);
  const semester = useSelector(state => state.semester);
  const jurusanSp = useSelector(state => state.jurusanSp);
  const kurikulum = useSelector(state => state.kurikulum);
  const guru = useSelector(state => state.guru);
  const dispatch = useDispatch();
  // Local State
  const [loadingButton, setloadingButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataSemester, setDataSemester] = useState([])
  const [dataJurusanSP, setDataJurusanSP] = useState([])
  const [dataKurikulum, setDataKurikulum] = useState([])
  const [dataGuru, setDataGuru] = useState([])

  const [error, setError] = useState({})
  // Local Ref
  const formRef = useRef();

  const getDataSemester = async (params = {}, signal = (new AbortController()).signal) => {
    return await semesterService.getDataSemester(params, signal);
  }

  const getDataJurusanSP = async (params = {}, signal = (new AbortController()).signal) => {
    return await jurusanSpService.getDataJurusanSP(params, signal);
  }

  const getDataKurikulum = async (params = {}, signal = (new AbortController()).signal) => {
    return await kurikulumService.getDataKurikulum(params, signal);
  }

  const getDataGuru = async (params = {}, signal = (new AbortController()).signal) => {
    return await guruService.getDataGuru(params);
  }

  const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onFinish = async (values) => {
    setloadingButton(true)
    setError({})
    try {
      values.sekolah_id = appSetting.sekolah_id;
      await rombelService.postDataRombel(values)
      setVisible(false)
      formRef.current.resetFields()
      props.onSuccess()
      openNotificationWithIcon("Berhasil", "Data baru berhasil ditambahkan", <CheckCircleOutlined style={{ color: "#52c41a" }} />)
    } catch (error) {
      if (error?.response?.status === 422) {
        setError(error.response.data.errors)
        openNotificationWithIcon("Peringatan", "Silahkan cek kembali formulir anda", <WarningOutlined style={{ color: "yellow" }} />)
      } else {
        setVisible(false)
        openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />)
      }
    } finally {
      setloadingButton(false)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean)
    },
    setup() {
      getDataSemester({ limit: 10, offset: 0 }, semester.controller.signal).then(res => dispatch(setGlobalDataSemester(res.data)) && semester.controller.abort());
      getDataKurikulum({ limit: 10, offset: 0 }, kurikulum.controller.signal).then(res => dispatch(setGlobalDataKurikulum(res.data)) && kurikulum.controller.abort());
      getDataJurusanSP({ limit: 10, offset: 0, sekolah_id: appSetting.sekolah_id }, jurusanSp.controller.signal).then(res => dispatch(setGlobalDataJurusanSP(res.data)) && jurusanSp.controller.abort());
      getDataGuru({ limit: 10, offset: 0, sekolah_id: appSetting.sekolah_id }, guru.controller.signal).then(res => dispatch(setGlobalDataGuru(res.data)) && guru.controller.abort());
    }
  }))

  useEffect(() => {
    if(visible) setDataSemester(semester.data.results)
  }, [semester, visible])

  useEffect(() => {
    if(visible) setDataKurikulum(kurikulum.data.results)
  }, [kurikulum, visible])

  useEffect(() => {
    if(visible) setDataJurusanSP(jurusanSp.data.results)
  }, [jurusanSp, visible])

  useEffect(() => {
    if(visible) setDataGuru(guru.data.results)
  }, [guru, visible])

  // Props Component
  const formItemProps = (key) => {
    return {
      name: key,
      validateStatus: error[key] ? "error" : null,
      help: error?.[key]?.[0],
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
        width={700}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={formRef}
        >
          <div style={{ diplay: "flex", justifyContent: "space-between" }}>
            <h3>Buat Data</h3>
          </div>
          <Form.Item label="Nama Rombel" {...formItemProps("nama")}>
            <Input placeholder="Akuntansi 4" />
          </Form.Item>
          <Form.Item label="Semester" {...formItemProps("semester_id")}>
            <Select
              showSearch
              placeholder="Pilih Semester"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataSemester({ limit: 10, offset: 0, search: keyword }).then(res => setDataSemester(res.data.results))}
            >
              {dataSemester.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Jurusan SP"  {...formItemProps("jurusan_sp_id")}>
            <Select
              showSearch
              placeholder="Pilih Jurusan"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataJurusanSP({ limit: 10, offset: 0, search: keyword }).then(res => setDataJurusanSP(res.data.results))}
            >
              {dataJurusanSP.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama_jurusan_sp}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Kurikulum"  {...formItemProps("kurikulum_id")}>
            <Select
              showSearch
              placeholder="Pilih Kurikulum"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataKurikulum({ limit: 10, offset: 0, search: keyword }).then(res => setDataKurikulum(res.data.results))}
            >
              {dataKurikulum.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama_kurikulum}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Guru" {...formItemProps("guru_id")}>
            <Select
              showSearch
              placeholder="Pilih Guru"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={(keyword) => getDataGuru({ limit: 10, offset: 0, search: keyword }).then(res => setDataGuru(res.data.results))}
            >
              {
                dataGuru.map(item => (<Option key={item.id} value={item.id}>{item.nama}</Option>))
              }
            </Select>
          </Form.Item>
          <Form.Item label="Tingkat" {...formItemProps("tingkat")}>
            <Select
              showSearch
              placeholder="Pilih Tingkat Kelas"
              optionFilterProp="children"
              filterOption={filterOption}
            >
              <Option value="10">Kelas X</Option>
              <Option value="11">Kelas XI</Option>
              <Option value="12">Kelas XII</Option>
            </Select>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: ".5rem", marginTop: ".5rem" }}>
            <Button key="back" onClick={() => setError({}) || setVisible(false)}>
              Batal
            </Button>
            <Button htmlType="submit" key="submit" type="primary" loading={loadingButton}>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal>
      <PembelajaranCreateForm/>
    </>
  )
})

export default RombelCreateForm;