/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import "antd/dist/antd.min.css";
import { Route, Switch } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import routes from "./routes/index";
import ProtectedRoute from "./routes/ProtectedRoute";

function App() {
  return (
    <div className="App">
      <Switch>
        {
          routes.map((route, index) => {
            return route.children ? (
              route.children.map((child, index) => {
                return child?.middleware?.protected ? (
                  <ProtectedRoute
                    key={index + child.path}
                    path={child.path}
                    middleware={child.middleware}
                    exact={child.exact}>
                    <route.component><child.component /></route.component>
                  </ProtectedRoute>
                ) : (
                  <Route
                    key={index + child.path}
                    path={child.path}
                    exact={child.exact}
                    component={() => <route.component><child.component /></route.component>}
                  />
                );
              })
            ) : (
              route?.middleware?.protected ? (
                <ProtectedRoute
                  key={index + route.path}
                  path={route.path}
                  middleware={route.middleware}
                  exact={route.exact}
                >
                  <route.component />
                </ProtectedRoute>
              ) :
                (
                  <Route
                    key={index + route.path}
                    path={route.path}
                    exact={route.exact}
                    component={() => <route.component />}
                  />
                )
            );
          })
        }
        {/* <Redirect from="/*" to="/dashboard" /> */}
      </Switch>
      {/* <div>Waiting for authentication</div> */}
    </div>
  );
}

export default App;
