import { Card, Col, Row, Table, Input, Tag } from "antd";
import API from "../services/api/pesertaDidik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setData, setPagination } from "../store/features/peserta-didik";

function PesertaDidik() {
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const pesertaDidik = useSelector(state => state.pesertaDidik)
  const dispatch = useDispatch()

  const getDataPesertaDidik = async (params = {}, signal = (new AbortController()).signal) => {
    setLoading(true)
    try {
      const res = await API.getDataPesertaDidik(params, signal);
      dispatch(setData(res.data))
      dispatch(setPagination(params))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {};
    params.offset =
      pagination.current === 1
        ? 0
        : (pagination.current - 1) * pagination.pageSize;
    params.limit = pagination.pageSize;
    params.current = pagination.current;
    if (typeof sorter.order !== "undefined") {
      params.sortField = sorter.field;
      params.sortOrder = sorter.order.replace("end", "");
    }
    getDataPesertaDidik({ ...pesertaDidik.pagination, ...params });
  };

  const onSearch = (e) => {
    getDataPesertaDidik({ ...pesertaDidik.pagination, search: e.target.value, current: 1, offset: 0 });
  }

  useEffect(() => {

    getDataPesertaDidik({ offset: 0, limit: 10 }, pesertaDidik.controller.signal);
    return () => { pesertaDidik.controller.abort() };
  }, []);

  return (
    <div className="layout-content">
      <Row className="rowgap-vbox">
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Data Peserta Didik"
          >
            <Row>
              <Col span={8} style={{ padding: "1rem" }}></Col>
              <Col
                span={8}
                offset={8}
                style={{
                  padding: "1rem",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Search
                  placeholder="input search text"
                  style={{ width: 200 }}
                  onKeyUp={onSearch}
                />
              </Col>
            </Row>
            <div className="table-responsive">
              <Table
                columns={[
                  {
                    title: "Nama",
                    dataIndex: "nama",
                    sorter: true,
                  },
                  {
                    title: "No Induk",
                    dataIndex: "no_induk",
                    sorter: true,
                  },
                  {
                    title: "NISN",
                    dataIndex: "nisn",
                    sorter: true,
                  },
                  {
                    title: "NIK",
                    dataIndex: "nik",
                    sorter: true,
                  },
                  {
                    title: "Jenis Kelamin",
                    dataIndex: "jenis_kelamin",
                    sorter: true,
                    render: (text, record) => {
                      if (record.jenis_kelamin === "L") {
                        return <Tag color="blue">Laki - laki</Tag>
                      } else if (record.jenis_kelamin === "P") {
                        return <Tag color="gold">Perempuan</Tag>
                      } else {
                        return <Tag color="red">Belum diisi</Tag>
                      }
                    },
                  },
                ]}
                rowKey={record => record.id}
                dataSource={pesertaDidik.data.results}
                pagination={{
                  current: pesertaDidik.pagination.current,
                  pageSize: pesertaDidik.pagination.limit,
                  total: pesertaDidik.data.results_found,
                }}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PesertaDidik;
