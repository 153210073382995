import {
  ArrowLeftOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import CardLoadingSoal from "../components/loaders/CardLoadingSoal";
import CardSoal from "../components/paket-ujian/CardSoal";
import SoalSelector from "../components/paket-ujian/SoalSelector";
import ujianService from "../services/api/ujian";
import remedialService from "../services/api/remedial";

const { Title } = Typography;

function RemedialUjianEditor() {
  const history = useHistory();
  const { id: ujianUUID, remedId: remedialUUID } = useParams();
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );
  const soalSelectorRef = useRef();
  const formRef = useRef();
  const [dataRemedial, setDataRemedial] = useState([]);
  const [dataUjian, setDataUjian] = useState([]);
  const [soalRemedial, setSoalRemedial] = useState([]);
  const [soalIds, setSoalIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ujianId, setUjianId] = useState(null);
  const [remedialId, setRemedialId] = useState(null);

  const addSoalHandler = (newSoal) => {
    if (soalIds.includes(newSoal.id)) return;
    setSoalRemedial((soal) => [...soal, newSoal]);
  };

  const removeSoalHandler = (soalId) => {
    setSoalRemedial((soal) => soal.filter((s) => s.id !== soalId));
  };

  const validateForm = () => {
    let validateSoal = soalIds && soalIds.length > 0;
    if (!validateSoal) message.error("Harus ada soal!");
    return validateSoal;
  };

  const submitHandler = async (values) => {
    if (!validateForm()) return;
    try {
      let payload = {
        ujian_id: ujianId,
        tgl_remedial: moment(values.tgl_remedial).format("YYYY-MM-DD"),
        soal_json: soalIds,
        jumlah_soal: values.jumlah_soal,
        waktu_mulai: moment(values.waktu_remedial[0], "HH:mm:ss").format(
          "HH:mm:ss"
        ),
        waktu_akhir: moment(values.waktu_remedial[1], "HH:mm:ss").format(
          "HH:mm:ss"
        ),
      };
      if (remedialUUID && remedialId) {
        await remedialService.updateRemedial(payload, remedialId); // Update ujian
        message.success("Ujian berhasil diupdate");
      } else if (ujianId) {
        await remedialService.postRemedial(payload); // Create new ujian
        message.success("Ujian berhasil dibuat");
      }
      history.push("/paket-ujian");
    } catch (error) {
      message.error("Terjadi kesalahan.");
    }
  };

  const fetchDataUjian = useCallback(async () => {
    try {
      setLoading(true);
      let res = await ujianService.getUjianByUUID(ujianUUID);
      setDataUjian({ nama_ujian: res.data.result.nama_ujian });
      if (!remedialUUID) {
        let soalRes = await remedialService.getSoalByIds(
          JSON.parse(res.data.result.soal_json)
        );
        setSoalRemedial(soalRes.data.results);
      }
      setUjianId(res.data.result.id);
    } catch (error) {
      message.error("Tidak dapat memuat halaman");
      history.replace("/paket-ujian");
    } finally {
      setLoading(false);
    }
  }, [ujianUUID, remedialUUID, history]);

  const fetchDataRemedial = useCallback(async () => {
    try {
      setLoading(true);
      let res = await remedialService.getRemedialByUUID(remedialUUID);
      let soalRes = await remedialService.getSoalByIds(
        JSON.parse(res.data.result.soal_json)
      );
      setDataRemedial({
        tgl_remedial: moment(res.data.result.tgl_remedial),
        jumlah_soal: res.data.result.jml_pg_tampil,
        waktu_remedial: [
          moment(res.data.result.waktu_mulai, "HH:mm:ss"),
          moment(res.data.result.waktu_akhir, "HH:mm:ss"),
        ],
      });
      setRemedialId(res.data.result.id);
      setSoalRemedial(soalRes.data.results);
    } catch (error) {
      message.error("Tidak dapat memuat halaman");
      history.replace("/paket-ujian");
    } finally {
      setLoading(false);
    }
  }, [remedialUUID, history]);

  useEffect(() => {
    if (formRef.current) formRef.current.setFieldsValue(dataRemedial);
  }, [dataRemedial]);

  useEffect(() => {
    setSoalIds(soalRemedial.map((soal) => soal.id));
  }, [soalRemedial]);

  useEffect(() => {
    if (ujianUUID) fetchDataUjian();
  }, [ujianUUID, fetchDataUjian]);

  useEffect(() => {
    if (remedialUUID) fetchDataRemedial();
  }, [remedialUUID, fetchDataRemedial]);

  useEffect(() => {
    if (!selectedMapel || !selectedMapel.mapel_id || !selectedMapel.tingkat) {
      message.warning("Pilih mata pelajaran");
      history.replace("/paket-ujian");
    }
  }, [selectedMapel, history]);

  return (
    <>
      <Card>
        <Row>
          <Col>
            <Title level={5} style={{ marginBottom: "1rem" }}>
              Data Remedial Ujian
            </Title>
          </Col>
        </Row>
        <Form
          ref={formRef}
          className="remedial-ujian-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          layout="horizontal"
          onFinish={submitHandler}
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} harus diisi.",
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Form.Item label="Jenis Remedial">
                <Select
                  defaultValue={"Remedial Ujian Sekolah"}
                  style={{ width: 200 }}
                  disabled
                >
                  <Select.Option value={""}>
                    Remedial Ujian Sekolah
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Nama Remedial" rules={[{ required: true }]}>
                <Input disabled value={`Remedial : ${dataUjian.nama_ujian}`} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"tgl_remedial"}
                label="Tanggal Remedial"
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} req>
            <Col span={8}>
              <Form.Item
                name={"waktu_remedial"}
                label="Waktu Remedial"
                rules={[{ required: true }]}
              >
                <TimePicker.RangePicker format={"HH:mm"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"jumlah_soal"}
                label="Jumlah Soal Tampil"
                rules={[{ required: true }]}
                initialValue={40}
              >
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            align="bottom"
            justify="end"
          >
            <Col>
              <Space>
                <NavLink to={"/paket-ujian"}>
                  <Button
                    className="bg-success"
                    style={{ background: "#91d5ff" }}
                  >
                    <ArrowLeftOutlined className="bg-success" />
                    Kembali
                  </Button>
                </NavLink>
                <Button
                  type="default"
                  style={{ background: "#73d13d" }}
                  htmlType={"submit"}
                >
                  <SaveOutlined />
                  Simpan
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row justify="space-between" align="middle" style={{ padding: "1rem 0" }}>
        <Col>
          <Title level={2}>
            <UnorderedListOutlined style={{ marginRight: "0.5rem" }} />
            {soalRemedial.length} Soal
          </Title>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            onClick={() => soalSelectorRef.current.setVisible(true)}
          >
            Tambahkan Soal
          </Button>
        </Col>
      </Row>
      {!loading ? (
        soalRemedial.map((soal, index) => (
          <CardSoal
            key={index}
            soalIndex={++index}
            onRemove={removeSoalHandler}
            soal={soal}
          />
        ))
      ) : (
        <CardLoadingSoal />
      )}
      <SoalSelector
        ref={soalSelectorRef}
        selectedIds={soalIds}
        onAddSoal={addSoalHandler}
      />
    </>
  );
}

export default RemedialUjianEditor;
