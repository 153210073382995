import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Table } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JURUSAN from "../../services/api/jurusan";
import JURUSAN_SP from "../../services/api/jurusanSP";
import KURIKULUM from "../../services/api/kurikulum";
import { setData as setDataJurusan } from "../../store/features/jurusan";
import { setData as setDataKurikulum } from "../../store/features/kurikulum";
import { openNotificationWithIcon } from "../notification/customNotification";

const FormAddJurusan = forwardRef((props, ref) => {
  // Component
  const { Option } = Select;
  // Global State
  const dataJurusan = useSelector(state => state.jurusan);
  const dataKurikulum = useSelector(state => state.kurikulum);
  const dispatch = useDispatch();
  // Local State
  const [ visible, setVisible ] = useState(false);
  const [ loadingButton, setLoadingButton ] = useState(false)
  const [ loadingTable, setLoadingTable ] = useState(false)
  const [ detailDataSekolah, setDetailDataSekolah ] = useState({});
  const [ dataJurusanSP, setDataJurusanSP ] = useState([]);
  const [ error, setError ] = useState({});
  // Local Ref
  const formRef = useRef();

  const getDataJurusan = async (params = {}) => {
    const res = await JURUSAN.getDataJurusan(params);
    dispatch(setDataJurusan(res.data))
  }

  const getDataKurikulum = async (params = {}) => {
    const res = await KURIKULUM.getDataKurikulum(params);
    dispatch(setDataKurikulum(res.data))
  }

  const getDataJurusanSP = async (params = {}) => {
    const res = await JURUSAN_SP.getDataJurusanSP(params);
    setDataJurusanSP(res.data.results)
    setLoadingTable(false)
  }

  const deleteJurusanSPByID = (id) => {
    Modal.confirm({ 
      title: "Hapus Data", 
      icon: <WarningOutlined/>, 
      content: "Apakah anda ingin menghapus data ini?", 
      okText: "Ya", 
      cancelText: "Tidak", 
      onOk: async () => {
        try {
          const res = await JURUSAN_SP.deleteDataJurusanSPByID(id);
          openNotificationWithIcon("Berhasil", "Data berhasil dihapus", <CheckCircleOutlined style={{ color: "#52c41a" }} />)
          setLoadingTable(true)
          getDataJurusanSP({ sekolah_id: detailDataSekolah.id })
        } catch (error) {
          openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />)
        }
    }})
  }

  const onSearchJurusan = (val) => {
    getDataJurusan({limit: 10, offset: 0, search: val})
  }

  const onSearchKurikulum = (val) => {
    getDataKurikulum({limit: 10, offset: 0, search: val})
  }

  const storeDataJurusanSP = async (values) => {
    values.sekolah_id = detailDataSekolah.id
    setError({})
    setLoadingButton(true)
    try {
      const res = await JURUSAN_SP.postDataJurusanSP(values)
      setLoadingButton(false)
      setLoadingTable(true)
      formRef.current.resetFields()
      openNotificationWithIcon("Berhasil", "Data baru berhasil ditambahkan", <CheckCircleOutlined style={{ color: "#52c41a" }} />)
      getDataJurusanSP({sekolah_id: detailDataSekolah.id})
    } catch (error) {
      if(error.response.status === 422) {
        setError(error.response.data.errors)
        openNotificationWithIcon("Peringatan", "Silahkan cek kembali formulir anda", <WarningOutlined style={{ color: "yellow" }} />)
      } else {
        openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />)
      }
    } finally {
      setLoadingButton(false)
    }
  }

  useEffect(() => {
    getDataJurusan({offset: 0, limit: 10}, dataJurusan.controller.signal)
    getDataKurikulum({offset: 0, limit: 10}, dataKurikulum.controller.signal)
    return () => { 
      dataJurusan.controller.abort() 
      dataKurikulum.controller.abort()
    }
  }, []);

  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean)
    },
    async setDetailDataSekolah(data) {
      setDataJurusanSP([])
      setLoadingTable(true)
      setDetailDataSekolah(data);
      await getDataJurusanSP({sekolah_id: data.id})
    }
  }))

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      width={1400}
      footer={null}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={storeDataJurusanSP}
        ref={formRef}
      >
        <div style={{ diplay: "flex", justifyContent: "space-between" }}>
          <h3>Tambah Jurusan</h3>
        </div>
        <Form.Item label="Nama Sekolah">
          <Input placeholder="SMK 1" value={detailDataSekolah.nama} readOnly/>
        </Form.Item>
        <Form.Item label="Pilih Jurusan" name="jurusan_id" validateStatus={error.jurusan_id ? "error" : null} help={error.jurusan_id ? error.jurusan_id[0] : null}>
          <Select
            showSearch
            placeholder="Pilih Jurusan"
            optionFilterProp="children"
            onSearch={onSearchJurusan}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataJurusan.data.results.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.nama_jurusan}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Pilih Kurikulum" name="kurikulum_id" validateStatus={error.kurikulum_id ? "error" : null} help={error.kurikulum_id ? error.kurikulum_id[0] : null}>
          <Select
            showSearch
            placeholder="Pilih Kurikulum"
            optionFilterProp="children"
            onSearch={onSearchKurikulum}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataKurikulum.data.results.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.nama_kurikulum}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Singkatan Jurusan" name="singkatan_jurusan">
          <Input placeholder="TKJ 2"/>
        </Form.Item>
        <Form.Item label="Nama Jurusan SP" name="nama_jurusan_sp">
          <Input placeholder="Teknik Komputer & Jaringan 2"/>
        </Form.Item>
        <Form.Item style={{ justifyContent: "flex-end" }}>
          <Button type="primary" htmlType="submit" loading={loadingButton}>
            Tambah
          </Button>
        </Form.Item>
      </Form>
      <Table 
        loading={loadingTable}
        rowKey={(record) => record.id}
        columns={[
          {
            title: 'Nama Jurusan',
            dataIndex: ['jurusan', 'nama_jurusan'],
          },
          {
            title: 'Nama Jurusan SP',
            dataIndex: 'nama_jurusan_sp',
          },
          {
            title: 'Singkatan Jurusan',
            dataIndex: 'singkatan_jurusan',
          },
          {
            title: 'Kurikulum',
            dataIndex: ['kurikulum', 'nama_kurikulum'],
          },
          {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
              <div>
                <Button danger onClick={() => deleteJurusanSPByID(record.id)}>Hapus</Button>
              </div>
            )
          }
        ]} 
        dataSource={dataJurusanSP}
      />
    </Modal>
  );
})

export default FormAddJurusan;