import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from 'qs';

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const sekolahService = {
  getDataSekolah: async (params, signal) => {
    const endpoint = `${API_URL}/sekolah?${qs.stringify(params)}`;
    return await axios.get(endpoint, { signal, ...headers });
  },
  postDataSekolah: async (data) => {
    const endpoint = `${API_URL}/sekolah`;
    return await axios.post(endpoint, data, headers);
  },
  getDataSekolahById: async (id) => {
    const endpoint = `${API_URL}/sekolah/${id}`;
    return await axios.get(endpoint, headers);
  },
  updateDataSekolah: async (data, id) => {
    const endpoint = `${API_URL}/sekolah/${id}`;
    return await axios.post(endpoint, data, headers);
  }
}

export default sekolahService;