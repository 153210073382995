import { Card, Col, Divider, Row, Skeleton, Space } from "antd";
import React from "react";

export default function CardLoadingSoal(props) {
  return (
    <Card bordered style={{ marginBottom: "1rem" }}>
      <Row justify="space-between">
        <Col>
          <Skeleton.Button active />
        </Col>
        <Col>
          <Space>
            <Skeleton.Button active />
            <Skeleton.Button active />
            <Skeleton.Button active />
          </Space>
        </Col>
      </Row>
      <Divider style={{ margin: "0.5rem" }} />
      <div style={{ paddingTop: "1rem" }}>
        <Row gutter={[12, 12]}>
          <Col>
            <Skeleton.Avatar active shape="square" size={128} />
          </Col>
          <Col style={{ flex: 1 }}>
            <Skeleton active paragraph={{ rows: 2 }} />
          </Col>
        </Row>
        <Divider style={{ margin: "0.5rem" }} />
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            style={{ padding: "0.5rem 0" }}
          >
            <Skeleton.Input active />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            style={{ padding: "0.5rem 0" }}
          >
            <Skeleton.Input active />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            style={{ padding: "0.5rem 0" }}
          >
            <Skeleton.Input active />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            style={{ padding: "0.5rem 0" }}
          >
            <Skeleton.Input active />
          </Col>
        </Row>
        <Divider style={{ margin: "0.7rem 0.5rem" }} />
        <Row justify="space-between">
          <Col>
            <Skeleton.Input active />
            <Divider type="vertical" />
            <Skeleton.Input active />
          </Col>
        </Row>
      </div>
    </Card>
  );
}
