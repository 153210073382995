import { Card, Col, Input, message, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataUjianTable from "../components/data-ujian/DataUjianTable";
import ujianService from "../services/api/ujian";
import { setData } from "../store/features/ujian-slice";

const { Search } = Input;
const DataUjian = () => {
  const dataUjian = useSelector((state) => state.ujian); // Data guru from store
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: 10,
    current: 1,
  });
  const dispatch = useDispatch();
  const detailModalRef = useRef();

  const getDataUjian = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        let res = await ujianService.getCurrentDataUjian(params);
        dispatch(
          setData({
            results: res.data.results,
            results_found: res.data.results_found,
          })
        );
        // console.log(res);
      } catch (error) {
        message.error("Tidak dapat memuat data");
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const tableChangeHandler = (pagination, filters, sorter) => {
    const params = {};
    params.offset = (pagination.current - 1) * pagination.pageSize;
    params.limit = pagination.pageSize;
    params.current = pagination.current;
    if (sorter.order && sorter.field) {
      params.sortField = sorter.field;
      params.sortOrder = sorter.order.replace("end", ""); // ascend to asc, descend to desc
    }
    setQueryParams((queryParams) => ({ ...queryParams, ...params }));
  };

  const openDetailModalHandler = (guruId) => {
    if (detailModalRef) {
      detailModalRef.current.setGuruId(guruId);
      detailModalRef.current.setVisible(true);
    }
  };
  const searchHandler = (keyword) => {
    setQueryParams((queryParams) => ({
      ...queryParams,
      current: 1,
      offset: 0,
      search: keyword,
    }));
  };

  useEffect(() => {
    getDataUjian(queryParams);
    return () => {};
  }, [getDataUjian, queryParams]);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Data Ujian"
          >
            <Row>
              <Col span={8} style={{ padding: "1rem" }}></Col>
              <Col
                span={8}
                offset={8}
                style={{
                  padding: "1rem",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Search
                  placeholder="input search text"
                  style={{ width: 200 }}
                  onSearch={(val) => searchHandler(val)}
                />
              </Col>
            </Row>
            <DataUjianTable
              dataSource={dataUjian.data.results}
              loading={loading}
              onClickDetail={openDetailModalHandler}
              onTableChange={tableChangeHandler}
              pagination={{
                current: queryParams.current,
                pageSize: queryParams.limit,
                total: dataUjian.data.results_found,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataUjian;
