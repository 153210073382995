import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from "qs";

const token = `Bearer ${localStorage.getItem("token")}`;

const headers = {
  headers: {
    authorization: token,
  },
};

const semesterService = {
  getDataSemester: async (params, signal) => {
    const endpoint = `${API_URL}/semester?${qs.stringify(params)}`;
    return await axios.get(endpoint, { signal, ...headers });
  },
};

export default semesterService;
