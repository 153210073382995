import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select } from "antd";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import kompetensiDasarService from "../../services/api/kompetensiDasar";
import mataPelajaranService from "../../services/api/mataPelajaran";
import { setData as setGlobalDataMapel } from "../../store/features/mata-pelajaran";
import { openNotificationWithIcon } from "../notification/customNotification";

const CreateKDModal = forwardRef((props, ref) => {
  const { Option } = Select;

  const mapel = useSelector((state) => state.mataPelajaran);
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [loadingButton, setloadingButton] = useState(false);
  const [error, setError] = useState({});
  const [dataMapel, setDataMapel] = useState([]);

  const formRef = useRef();

  const getDataMataPelajaran = async (
    params = {},
    signal = new AbortController().signal
  ) => {
    return await mataPelajaranService.getDataMataPelajaran(params, signal);
  };

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useImperativeHandle(ref, () => ({
    setVisible: (isVisible) => setVisible(isVisible),
  }));

  // Props Component
  const formItemProps = (key) => {
    return {
      name: key,
      validateStatus: error[key] ? "error" : null,
      help: error?.[key]?.[0],
    };
  };

  const onFinish = async (values) => {
    setloadingButton(true);
    setError({});
    try {
      await kompetensiDasarService.postDataKompetensiDasar(values);
      setVisible(false);
      formRef.current.resetFields();
      props.onSuccess();
      openNotificationWithIcon(
        "Berhasil",
        "Data baru berhasil ditambahkan",
        <CheckCircleOutlined style={{ color: "#52c41a" }} />
      );
    } catch (error) {
      if (error?.response?.status === 422) {
        setError(error.response.data.errors);
        openNotificationWithIcon(
          "Peringatan",
          "Silahkan cek kembali formulir anda",
          <WarningOutlined style={{ color: "yellow" }} />
        );
      } else {
        setVisible(false);
        openNotificationWithIcon(
          "Internal Server Error",
          "Server sedang bermasalah, silahkan coba beberapa saat lagi",
          <WarningOutlined style={{ color: "#ff0000" }} />
        );
      }
    } finally {
      setloadingButton(false);
    }
  };

  useEffect(() => {
    if (visible) {
      getDataMataPelajaran({
        limit: 10,
        offset: 0,
        search: selectedMapel.mapel_id,
      }).then((res) => {
        dispatch(setGlobalDataMapel(res.data));
        formRef.current.setFieldsValue({
          mata_pelajaran_id: selectedMapel.mapel_id,
        });
        // defaultValue={selectedMapel.mapel_id}
      });
    }
  }, [visible, dispatch, selectedMapel]);

  useEffect(() => {
    setDataMapel(mapel.data.results);
  }, [mapel.data.results]);

  return (
    <Modal
      width={700}
      footer={false}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef}
      >
        <div style={{ diplay: "flex", justifyContent: "space-between" }}>
          <h3>Buat Data</h3>
        </div>
        <Form.Item
          label="Kompentensi Dasar"
          {...formItemProps("kompetensi_dasar")}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item label="Mapel" {...formItemProps("mata_pelajaran_id")}>
          <Select
            showSearch
            placeholder="Pilih Mapel"
            optionFilterProp="children"
            filterOption={filterOption}
            onSearch={(keyword) =>
              getDataMataPelajaran({
                limit: 10,
                offset: 0,
                search: keyword,
              }).then((res) => setDataMapel(res.data.results))
            }
            disabled
          >
            {dataMapel.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.nama}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Urutan" {...formItemProps("urutan")}>
          <Input placeholder="" />
        </Form.Item>
        <Form.Item label="Jenis" {...formItemProps("jenis")}>
          <Input placeholder="" />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            marginTop: ".5rem",
          }}
        >
          <Button key="back" onClick={() => setError({}) || setVisible(false)}>
            Batal
          </Button>
          <Button
            htmlType="submit"
            key="submit"
            type="primary"
            loading={loadingButton}
          >
            Simpan
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

export default CreateKDModal;
