import { createSlice } from "@reduxjs/toolkit";

const sekolah = createSlice({
  name: "sekolah",
  initialState: {
    data: {
      results: [],
      results_found: 0,
    },
    selectedData: {},
    pagination: {
      current: 1,
      limit: 10,
      offset: 0,
    },
    controller: new AbortController()
  },
  reducers: {
    setData: (state, action) => {
      return  {
        ...state,
        data: action.payload
      }
    },
    setSelectedData: (state, action) => {
      return {
        ...state,
        selectedData: action.payload
      }
    },
    setPagination: (state, action) => {
      return {
        ...state,
        pagination: action.payload
      }
    }
  },
})

export const { setData, setSelectedData, setPagination   } = sekolah.actions
export default sekolah.reducer