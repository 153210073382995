import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button, Input, Select, Typography } from "antd";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../notification/customNotification";
import sekolahService from "../../services/api/sekolah";
import refWilayahService from "../../services/api/refWilayah";
import { setData as setGlobalDataWilayah } from "../../store/features/ref-wilayah";
import { setData as setGlobalDataGuru } from "../../store/features/guru";
import ImageUpload from "../fileupload/ImageUpload";
import './ImageUpload.css';
import guruService from "../../services/api/guru";

const FormDetail = forwardRef((props, ref) => {
  // Component
  const { TextArea } = Input;
  const { Option } = Select;
  const { Text } = Typography;
  // Global State
  const wilayah = useSelector(state => state.refWilayah);
  const guru = useSelector(state => state.guru);
  // Local State
  const [visible, setVisible] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [detailDataSekolah, setDetailDataSekolah] = useState({});
  const [dataWilayah, setDataWilayah] = useState([]);
  const [dataGuru, setDataGuru] = useState([]);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const formRef = useRef();
  const imageUploadRef = useRef();
  const [form] = Form.useForm();

  // Methods
  const getDataWilayah = async (params = {}, signal = (new AbortController()).signal) => {
    return await refWilayahService.getDataWilayah(params, signal);
  };

  const getDataGuru = async (params = {}, signal = (new AbortController()).signal) => {
    return await guruService.getDataGuru(params);
  };

  const searchDataWilayah = (keyword) => {
    getDataWilayah({ search: keyword, limit: 10, offset: 0 }).then(res => setDataWilayah(res.data.results));
  };

  const searchDataGuru = (keyword) => {
    getDataGuru({ search: keyword, limit: 10, offset: 0 }).then(res => setDataGuru(res.data.results))
  }

  const formDataHandler = (object = {}) => {
    const data = new FormData();
    for (const key in object) if (object[key] !== null && object[key] !== undefined) data.append(key, object[key]) || console.log(object[key]);
    data.append('_method', 'PUT');
    return data;
  }

  const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const clearForm = () => {
    setError({});
    formRef.current.resetFields();
    imageUploadRef.current.setFileList([]);
  }

  const getDetailSekolahByID = async (id, signal = (new AbortController()).signal) => {
    return await sekolahService.getDataSekolahById(id);
  }

  const updateDataHandler = async (values) => {
    setLoadingButton(true);
    const data = formDataHandler(values);
    try {
      setError({});
      await sekolahService.updateDataSekolah(data, detailDataSekolah.id);
      setVisible(false);
      openNotificationWithIcon("Berhasil", "Data berhasil diperbarui", <CheckCircleOutlined style={{ color: "#52c41a" }} />)
      clearForm();
      props.getDataSekolah()
    } catch (error) {
      if (error.response.status === 422) {
        setError(error.response.data.errors);
        openNotificationWithIcon("Peringatan", "Silahkan cek kembali formulir anda", <WarningOutlined style={{ color: "yellow" }} />);
      } else {
        setVisible(false);
        openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />);
      }
    } finally {
      setLoadingButton(false);
    }
  };

  // Ref method handler
  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean);
    },
    setup() {
      getDataWilayah({ limit: 10, offset: 0 }, wilayah.controller.signal).then(res => dispatch(setGlobalDataWilayah(res.data)));
      getDataGuru({}, guru.controller.signal).then(res => dispatch(setGlobalDataGuru(res.data)));
    }
  }))

  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean)
    },
    setup(data) {
      if(formRef.current) clearForm()
      getDetailSekolahByID(data.sekolah_id).then(res => {
        getDataWilayah({ limit: 10, offset: 0, search: res.data.result.kode_wilayah }).then(res => setDataWilayah(res.data.results));
        let { guru_id, logo_sekolah, sekolah_id } = res.data?.result;
        if (guru_id) getDataGuru({ limit: 10, offset: 0, search: guru_id }).then(res => setDataGuru(res.data.results));
        if (logo_sekolah) {
          imageUploadRef.current.setFileList([
            {
              uid: sekolah_id,
              name: logo_sekolah,
              status: 'done',
              url: `http://127.0.0.1:8000/images/uploads/${logo_sekolah}`,
            }
          ])
        }
        setDetailDataSekolah(res.data.result);
      });
    }
  }))

  useEffect(() => {
    setDataGuru(guru.data.results)
    return () => { guru.controller.abort(); }
  }, [guru]);

  useEffect(() => {
    setDataWilayah(wilayah.data.results)
    return () => { wilayah.controller.abort(); }
  }, [wilayah]);

  useEffect(() => {
    if(formRef.current) formRef.current.setFieldsValue(detailDataSekolah)
  }, [detailDataSekolah]);

  // Props Component
  const formItemProps = (key) => {
    return {
      name: key,
      validateStatus: error[key] ? "error" : null,
      help: error?.[key]?.[0],
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        footer={false}
        onCancel={() => setError({}) || setVisible(false)}
        width={700}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={updateDataHandler}
          autoComplete="off"
          ref={formRef}
          form={form}
        >
          <div style={{ diplay: "flex", justifyContent: "space-between" }}>
            <h3>Buat Data</h3>
          </div>
          <Form.Item label="NPSN" {...formItemProps("npsn")}>
            <Input placeholder="10xxxxxxx" />
          </Form.Item>
          <Form.Item label="Nama Sekolah" {...formItemProps("nama")}>
            <Input placeholder="SMK, SMA, MTS, MA" />
          </Form.Item>
          <Form.Item label="NSS" {...formItemProps("nss")}>
            <Input placeholder="10xxxxxxx" />
          </Form.Item>
          <Form.Item label="Alamat Sekolah" {...formItemProps("alamat")}>
            <TextArea
              placeholder="Jl. Dr. Soepomo No. 1"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item label="Desa Kelurahan" {...formItemProps("desa_kelurahan")}>
            <Input placeholder="Kel. Bojongherang" />
          </Form.Item>
          <Form.Item label="Kecamatan" {...formItemProps("kecamatan")}>
            <Input placeholder="Cianjur" />
          </Form.Item>
          <Form.Item label="Kode Wilayah" {...formItemProps("kode_wilayah")}>
            <Select
              showSearch
              placeholder="Pilih Wilayah"
              optionFilterProp="children"
              onSearch={searchDataWilayah}
              filterOption={filterOption}
            >
              {dataWilayah.map((item, index) => (
                <Option key={index} value={item.kode_wilayah}>
                  {item.nama}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Kabupaten" {...formItemProps("kabupaten")}>
            <Input placeholder="Cianjur" />
          </Form.Item>
          <Form.Item label="Provinsi" {...formItemProps("provinsi")}>
            <Input placeholder="Cianjur" />
          </Form.Item>
          <Form.Item label="Kode POS" {...formItemProps("kode_pos")}>
            <Input type="number" placeholder="43215" />
          </Form.Item>
          <Form.Item label="Lintang" {...formItemProps("lintang")}>
            <Input placeholder="-232243215" />
          </Form.Item>
          <Form.Item label="Bujur" name="bujur" {...formItemProps("bujur")}>
            <Input placeholder="-232243215" />
          </Form.Item>
          <Form.Item label="No Telp" {...formItemProps("no_telp")}>
            <Input placeholder="(+022) 8212 3938 33" />
          </Form.Item>
          <Form.Item label="No Fax" {...formItemProps("no_fax")}>
            <Input placeholder="290182312321" />
          </Form.Item>
          <Form.Item label="Email" {...formItemProps("email")}>
            <Input type="email" placeholder="smk@bisa.com" />
          </Form.Item>
          <Form.Item label="Website" {...formItemProps("website")}>
            <Input placeholder="https://smkbisa.com" />
          </Form.Item>
          <Form.Item label="Guru" {...formItemProps("guru_id")}>
            <Select
              showSearch
              placeholder="Pilih Guru"
              optionFilterProp="children"
              onSearch={searchDataGuru}
              filterOption={filterOption}
            >
              {
                dataGuru.map(item => (<Option key={item.id} value={item.id}>{item.nama}</Option>))
              }
            </Select>
          </Form.Item>
          <Form.Item label="Status Sekolah" {...formItemProps("status_sekolah")}>
            <Select
              showSearch
              placeholder="Pilih Status"
              optionFilterProp="children"
              filterOption={filterOption}
            >
              <Option value={1}>Aktif</Option>
              <Option value={0}>Tidak Aktif</Option>
            </Select>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: ".5rem" }}>
            <Text>Logo Sekolah</Text>
          </div>
          <ImageUpload ref={imageUploadRef} name="logo_sekolah" />
          <div style={{ textAlign: "center", width: "100%" }}>
            {
              error.logo_sekolah ? (<Text type="danger">{error.logo_sekolah[0]}</Text>) : null
            }
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: ".5rem", marginTop: ".5rem" }}>
            <Button key="back" onClick={() => setError({}) || setVisible(false)}>
              Batal
            </Button>
            <Button htmlType="submit" key="submit" type="primary" loading={loadingButton}>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
})

export default FormDetail;