const buttons = [
  {
    group: "Dasar",
    buttonPerRow: 4,
    buttons: [
      { label: "a^2", latex: "^2" },
      { label: "a_x", latex: "_{ }" },
      { label: "\\sqrt[x]{a}", latex: "\\sqrt[ ]{ }" },
      { label: "\\frac{a}{x}", latex: "\\frac{ }{ }" },
      { label: "\\frac{a}{x}", latex: "\\frac{ }{ }" },
      { label: "\\lceil x\\rceil", latex: "\\lceil x\\rceil" },
      { label: "\\lfloor x\\rfloor", latex: "\\lfloor x\\rfloor" },
      {
        label: "\\frac{\\text{d}x}{\\text{d}y}",
        latex: "\\frac{\\text{d}x}{\\text{d}y}",
      },
      {
        label: "\\frac{\\partial x}{\\partial y}",
        latex: "\\frac{\\partial x}{\\partial y}",
      },
      { label: "\\int _x^y", latex: "\\int _x^y" },
      { label: "\\oint _x^y", latex: "\\oint _x^y" },
      { label: "\\log _xy", latex: "\\log _xy" },
      { label: "\\lim _{x\\rightarrow y}", latex: "\\lim _{x\\rightarrow y}" },
      { label: "\\lim _{x\\rightarrow y}", latex: "\\lim _{x\\rightarrow y}" },
      { label: "\\sum _x^y", latex: "\\sum _x^y" },
      { label: "\\prod _x^y", latex: "\\prod _x^y" },
      { label: "\\overleftarrow{xy}", latex: "\\overleftarrow{xy}" },
      { label: "\\overline{xy}", latex: "\\overline{xy}" },
      { label: "\\overrightarrow{xy}", latex: "\\overrightarrow{xy}" },
      { label: "\\cdot", latex: "\\cdot" },
    ],
  },
  {
    group: "Dasar",
    buttonPerRow: 3,
    buttons: [
      { label: "+", latex: "+" },
      { label: "-", latex: "-" },
      { label: "\\pm", latex: "\\pm" },
      { label: "\\times", latex: "\\times" },
      { label: "\\div", latex: "\\div" },
      { label: "=", latex: "=" },
      { label: "<", latex: "<" },
      { label: ">", latex: ">" },
      { label: "\\ne", latex: "\\ne" },
      { label: "\\le", latex: "\\le" },
      { label: "\\ge", latex: "\\ge" },
      { label: "\\equiv", latex: "\\equiv" },
      { label: "\\sim", latex: "\\sim" },
      { label: "\\approx", latex: "\\approx" },
      { label: "\\cong", latex: "\\cong" },
    ],
  },
  {
    group: "Dasar",
    buttonPerRow: 3,
    buttons: [
      { label: "\\pi", latex: "\\pi" },
      { label: "\\theta", latex: "\\theta" },
      { label: "\\Delta", latex: "\\Delta" },
      { label: "\\alpha", latex: "\\alpha" },
      { label: "\\beta", latex: "\\beta" },
      { label: "\\nabla", latex: "\\nabla" },
      { label: "\\parallel", latex: "\\parallel" },
      { label: "\\perp", latex: "\\perp" },
      { label: "\\angle", latex: "\\angle" },
      { label: "\\degree", latex: "\\degree" },
      { label: "\\infty", latex: "\\infty" },
      { label: "\\propto", latex: "\\propto" },
      { label: "\\leftarrow", latex: "\\leftarrow" },
      { label: "\\rightarrow", latex: "\\rightarrow" },
      { label: "\\leftrightarrow", latex: "\\leftrightarrow" },
    ],
  },
  {
    group: "Dasar",
    buttonPerRow: 5,
    buttons: [
      { label: "\\alpha", latex: "\\alpha" },
      { label: "\\beta", latex: "\\beta" },
      { label: "\\gamma", latex: "\\gamma" },
      { label: "\\delta", latex: "\\delta" },
      { label: "\\epsilon", latex: "\\epsilon" },
      { label: "\\zeta", latex: "\\zeta" },
      { label: "\\eta", latex: "\\eta" },
      { label: "\\theta", latex: "\\theta" },
      { label: "\\iota", latex: "\\iota" },
      { label: "\\kappa", latex: "\\kappa" },
      { label: "\\lambda", latex: "\\lambda" },
      { label: "\\mu", latex: "\\mu" },
      { label: "\\nu", latex: "\\nu" },
      { label: "\\xi", latex: "\\xi" },
      { label: "\\o", latex: "\\o" },
      { label: "\\pi", latex: "\\pi" },
      { label: "\\rho", latex: "\\rho" },
      { label: "\\sigma", latex: "\\sigma" },
      { label: "\\tau", latex: "\\tau" },
      { label: "\\upsilon", latex: "\\upsilon" },
      { label: "\\phi", latex: "\\phi" },
      { label: "\\chi", latex: "\\chi" },
      { label: "\\psi", latex: "\\psi" },
      { label: "\\omega", latex: "\\omega" },
    ],
  },
  {
    group: "Greek",
    buttonPerRow: 5,
    buttons: [
      { label: "A", latex: "A" },
      { label: "B", latex: "B" },
      { label: "\\Gamma", latex: "\\Gamma" },
      { label: "\\Delta", latex: "\\Delta" },
      { label: "E", latex: "E" },
      { label: "Z", latex: "Z" },
      { label: "H", latex: "H" },
      { label: "\\Theta", latex: "\\Theta" },
      { label: "I", latex: "I" },
      { label: "K", latex: "K" },
      { label: "\\Lambda", latex: "\\Lambda" },
      { label: "M", latex: "M" },
      { label: "N", latex: "N" },
      { label: "\\Xi", latex: "\\Xi" },
      { label: "O", latex: "O" },
      { label: "\\Pi", latex: "\\Pi" },
      { label: "P", latex: "P" },
      { label: "\\Sigma", latex: "\\Sigma" },
      { label: "T", latex: "T" },
      { label: "\\Upsilon", latex: "\\Upsilon" },
      { label: "\\Phi", latex: "\\Phi" },
      { label: "X", latex: "X" },
      { label: "\\Psi", latex: "\\Psi" },
      { label: "\\Omega", latex: "\\Omega" },
    ],
  },
  {
    group: "Greek",
    buttonPerRow: 4,
    buttons: [
      { label: "\\subset", latex: "\\subset" },
      { label: "\\subseteq", latex: "\\subseteq" },
      { label: "\\notsubset", latex: "\\notsubset" },
      { label: "\\notsubseteq", latex: "\\notsubseteq" },
      { label: "\\supset", latex: "\\supset" },
      { label: "\\supseteq", latex: "\\supseteq" },
      { label: "\\notsupset", latex: "\\notsupset" },
      { label: "\\notsupseteq", latex: "\\notsupseteq" },
      { label: "\\in", latex: "\\in" },
      { label: "\\ni", latex: "\\ni" },
      { label: "\\notin", latex: "\\notin" },
      { label: "\\notni", latex: "\\notni" },
      { label: "\\cup", latex: "\\cup" },
      { label: "\\cap", latex: "\\cap" },
      { label: "\\forall", latex: "\\forall" },
      { label: "\\exists", latex: "\\exists" },
      { label: "\\vee", latex: "\\vee" },
      { label: "\\wedge", latex: "\\wedge" },
      { label: "\\because", latex: "\\because" },
      { label: "\\therefore", latex: "\\therefore" },
    ],
  },
  {
    group: "Advance",
    buttonPerRow: 3,
    buttons: [
      { label: "\\longleftarrow", latex: "\\longleftarrow" },
      { label: "\\longrightarrow", latex: "\\longrightarrow" },
      { label: "\\longleftrightarrow", latex: "\\longleftrightarrow" },
      { label: "\\Longleftarrow", latex: "\\Longleftarrow" },
      { label: "\\Longrightarrow", latex: "\\Longrightarrow" },
      { label: "\\Longleftrightarrow", latex: "\\Longleftrightarrow" },
      { label: "\\uparrow", latex: "\\uparrow" },
      { label: "\\Uparrow", latex: "\\Uparrow" },
      { label: "\\updownarrow", latex: "\\updownarrow" },
      { label: "\\downarrow", latex: "\\downarrow" },
      { label: "\\Downarrow", latex: "\\Downarrow" },
      { label: "\\Updownarrow", latex: "\\Updownarrow" },
      { label: "\\ldots", latex: "\\ldots" },
      { label: "\\cdots", latex: "\\cdots" },
      { label: "\\circ", latex: "\\circ" },
    ],
  },
];

export default buttons;
