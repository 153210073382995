import { Card, Col, Divider, Row, Skeleton, Space } from "antd";
import React from "react";

export default function CardLoadingUjian() {
  return (
    <Card bordered style={{ marginBottom: "1rem" }}>
      <Row gutter={[12, 12]}>
        <Col>
          <Skeleton.Avatar active shape="square" size={144} />
        </Col>
        <Col style={{ flex: 1 }}>
          <div style={{ marginBottom: "1rem" }}>
            <Skeleton.Input active size="large" />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Space>
              <Skeleton.Input active size="small" />
              <Skeleton.Input active size="small" />
              <Skeleton.Input active size="small" />
            </Space>
          </div>
          <div>
            <Space>
              <Skeleton.Input active/>
            </Space>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
