import { Form, Input, message, Modal, Select } from "antd";
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import guruService from "../../services/api/guru";
import referenceService from "../../services/api/references";

const GuruDetailModal = forwardRef((props, ref) => {
  const { onSuccessUpdate } = props;
  // Modal states
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // Guru detail
  const [guruId, setGuruId] = useState(null);
  const [guruDetail, setGuruDetail] = useState({});
  // References data
  const [refAgama, setRefAgama] = useState([]);
  const [refJenisPtk, setRefJenisPtk] = useState([]);
  const [refStatusKepegawaian, setRefStatusKepegawaian] = useState([]);
  // Form ref
  const formRef = useRef();

  // Method that exposed to the parent component
  useImperativeHandle(ref, () => ({
    setVisible: (visible) => setVisible(visible),
    setGuruId: (id) => setGuruId(id),
  }));

  const getReferences = useCallback(async () => {
    let { data: agama } = await referenceService.getAgama();
    let { data: jenisPtk } = await referenceService.getJenisPtk();
    let { data: statusKepegawaian } =
      await referenceService.getStatusKepegawaian();
    setRefAgama(agama);
    setRefJenisPtk(jenisPtk);
    setRefStatusKepegawaian(statusKepegawaian);
  }, []);

  const getGuruDetail = useCallback(async (id) => {
    let res = await guruService.getDataGuruById(id);
    setGuruDetail(res.data.result);
  }, []);

  const updateHandler = async () => {
    setLoading(true);
    try {
      let data = formRef.current.getFieldsValue();
      await guruService.updateDataGuru(data, guruId);
      message.success("Data berhasil diupdate");
      onSuccessUpdate();
    } catch (error) {
      message.error("Terjadi kesalahan");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReferences();
  }, [getReferences]);

  useEffect(() => {
    if (visible && guruId) getGuruDetail(guruId);
  }, [getGuruDetail, guruId, visible]);

  useEffect(() => {
    if (formRef.current) formRef.current.setFieldsValue(guruDetail);
  }, [guruDetail]);

  return (
    <Modal
      title="Basic Modal"
      visible={visible}
      onOk={() => updateHandler()}
      onCancel={() => setVisible(false)}
      okText={"Simpan"}
      cancelText={"Tutup"}
      width={"50rem"}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        ref={formRef}
        onFinish={updateHandler}
      >
        <Form.Item name={"nama"} label="Nama" rules={[{ required: true }]}>
          <Input value={guruDetail.nama} disabled={loading} />
        </Form.Item>

        <Form.Item name={"nuptk"} label="NUPTK">
          <Input value={guruDetail.nuptk} disabled={loading} />
        </Form.Item>

        <Form.Item name={"nip"} label="NIP">
          <Input />
        </Form.Item>

        <Form.Item name={"nik"} label="NIK">
          <Input />
        </Form.Item>

        <Form.Item
          name="jenis_kelamin"
          label="Jenis Kelamin"
          rules={[{ required: true, message: "Jenis kelamin harus diisi!" }]}
        >
          <Select placeholder="Pilih jenis kelamin">
            <Select.Option value="L">Laki-laki</Select.Option>
            <Select.Option value="P">Perempuan</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={"tempat_lahir"}
          label="Tempat Lahir"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"tanggal_lahir"}
          label="Tanggal Lahir"
          rules={[{ required: true }]}
        >
          <Input type={"date"} />
        </Form.Item>

        <Form.Item
          name="agama_id"
          label="Agama"
          rules={[{ required: true, message: "Agama harus diisi!" }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Pilih agama"
          >
            {refAgama.map((agama, index) => (
              <Select.Option value={agama.id} key={index}>
                {agama.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={"alamat"} label="Alamat">
          <Input.TextArea />
        </Form.Item>

        <Form.Item name={"rt"} label="RT">
          <Input />
        </Form.Item>

        <Form.Item name={"rw"} label="RW">
          <Input />
        </Form.Item>

        <Form.Item name={"desa_kelurahan"} label="Desa/Kelurahan">
          <Input />
        </Form.Item>

        <Form.Item name={"kecamatan"} label="Kecamatan">
          <Input />
        </Form.Item>

        <Form.Item name={"kode_pos"} label="Kode POS">
          <Input />
        </Form.Item>

        <Form.Item name={"no_hp"} label="Telp/HP">
          <Input />
        </Form.Item>

        <Form.Item name={"email"} label="Email">
          <Input />
        </Form.Item>

        <Form.Item name="jenis_ptk_id" label="Jenis PTK">
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Pilih jenis PTK"
          >
            {refJenisPtk.map((ptk, index) => (
              <Select.Option value={ptk.id} key={index}>
                {ptk.jenis_ptk}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="status_kepegawaian_id" label="Status Kepegawaian">
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Pilih status"
          >
            {refStatusKepegawaian.map((status, index) => (
              <Select.Option value={status.id} key={index}>
                {status.nama}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default GuruDetailModal;
