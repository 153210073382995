import { Form, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import pembelajaranService from "../../services/api/pembelajaran";
import {
  setDataMapel,
  setSelectedDataMapel,
} from "../../store/features/app-setting";

const ExamFilter = () => {
  const { Option } = Select;

  const appSetting = useSelector((state) => state.appSetting);
  const user = useSelector((state) => state.authUser.user);
  const mapel = useSelector((state) => state.appSetting.mapel);
  const dispatch = useDispatch();

  const getDataPembelajaranByUser = async (
    params = {},
    signal = new AbortController().signal
  ) => {
    return await pembelajaranService.getDataPembelajaranByUser(params);
  };

  const handleChange = (value) => {
    const selected = mapel.data.find(
      (m) => `${m.mapel_id}-${m.tingkat}` === value
    );
    localStorage.setItem("pembelajaran", JSON.stringify(selected));
    dispatch(
      setSelectedDataMapel({
        mapel_id: selected.mapel_id,
        nama_mapel: selected.nama_mapel,
        tingkat: selected.tingkat,
      })
    );
  };

  useEffect(() => {

    //fixed user.sekolah.id
    const sekolah_id = 1
    const params = {
      semester_id: appSetting.semester_id,
      guru_id: user.guru_id,
      sekolah_id: sekolah_id,
    };
    console.log(params)
    getDataPembelajaranByUser(params, appSetting.mapel.controller.signal).then(
      (res) => {
        dispatch(setDataMapel(res.data.results));
      }
    );

    return () => {
      appSetting.mapel.controller.abort();
    };
  }, [dispatch]);

  return (
    <Form.Item label="Pilih Mata Pelajaran">
      <Select
        onChange={handleChange}
        placeholder="Pilih Mapel"
        style={{ minWidth: "400px" }}
        value={{
          value:
            mapel && mapel.selected && mapel.selected.mapel_id
              ? `${mapel?.selected?.mapel_id}-${mapel?.selected?.tingkat}`
              : null,
        }}
      >
        {mapel.data.map((item) => (
          <Option
            key={`${item.mapel_id}-${item.tingkat}`}
            value={`${item.mapel_id}-${item.tingkat}`}
          >
            {item.nama_mapel} -{" "}
            {item.tingkat === 1
              ? "X"
              : item.tingkat === 2
              ? "XI"
              : item.tingkat === 3
              ? "XII"
              : "-"}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ExamFilter;
