import { CheckSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";
import styles from "./CardSoal.module.css";
const { Title, Text } = Typography;

const getTingkatKelas = (jenjang) => {
  let tingkat = 12;
  switch (jenjang) {
    case 1:
      tingkat = 10;
      break;
    case 2:
      tingkat = 11;
      break;
    default:
      tingkat = 12;
  }
  return tingkat;
};

export default function CardAddSoal(props) {
  const { soal, onClickAdd, isAdded } = props;
  return (
    <Card bordered className={styles["card-soal"]}>
      <Row justify="space-between">
        <Col>
          <Title level={5}>
            <CheckSquareOutlined />{" "}
            {soal.jenis_soal === 1 ? "Pilihan Ganda" : "Essay"}
          </Title>
        </Col>
        <Col>
          <Button type="primary" disabled={isAdded} ghost onClick={onClickAdd}>
            {!isAdded ? "Tambahkan Soal" : "Sudah ditambahkan"}
          </Button>
        </Col>
      </Row>
      <div style={{ paddingTop: "1rem" }}>
        <Row gutter={[12, 12]}>
          {soal.gambar && (
            <Col>
              <Image
                src={soal.gambar}
                className={styles["soal-image"]}
                loading="lazy"
              />
            </Col>
          )}
          <Col>
            <Title level={5}>Pertanyaan : </Title>
            <div
              dangerouslySetInnerHTML={{ __html: soal.teks }}
              className={styles["soal-teks"]}
            />
          </Col>
        </Row>
        <Divider orientation="left" plain style={{ margin: "1.2rem 0" }}>
          Pilihan Jawaban
        </Divider>
        <Row>
          {soal.opsi.map((opsi, key) => (
            <Col key={key} xs={{ span: 24 }} sm={{ span: 12 }}>
              <Space>
                {soal.opsi_jawaban_id.includes(opsi.id) ? (
                  <div
                    className={`${styles["answer-indicator"]} ${styles["true-answer-indicator"]}`}
                  />
                ) : (
                  <div
                    className={`${styles["answer-indicator"]} ${styles["wrong-answer-indicator"]}`}
                  />
                )}
                {opsi.gambar ? (
                  <Image src={opsi.gambar} className={styles["opsi-image"]} />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: opsi.teks }} />
                )}
              </Space>
            </Col>
          ))}
        </Row>
        <Divider className={styles["card-divider"]} />
        <Space>
          <Text style={{ fontSize: "0.8rem", color: "#606060" }}>
            <b>Mata Pelajaran: </b>
            <span>{soal.mata_pelajaran.nama}</span>
          </Text>
          <Divider type="vertical" />
          <Text style={{ fontSize: "0.8rem", color: "#606060" }}>
            <b>Tingkat Kelas: </b>
            <span> {getTingkatKelas(soal.jenjang)}</span>
          </Text>
          <Divider type="vertical" />
          <Text style={{ fontSize: "0.8rem", color: "#606060" }}>
            <b>K.D: </b>
            <span> {soal.kompetensi_dasar.kompetensi_dasar}</span>
          </Text>
        </Space>
      </div>
    </Card>
  );
}
