import { FunctionOutlined } from "@ant-design/icons";
import { Col, Divider, Modal, Row, Typography } from "antd";
import katex from "katex";
import React, { useRef, useState } from "react";
import { EditableMathField } from "react-mathquill";
import { useDispatch, useSelector } from "react-redux";
import { closeMathEditor } from "../../store/features/soal-editor";
import latexButtons from "./latex-buttons";
import MathEditorButton from "./MathEditorButton";

const { Title, Text } = Typography;

export default function MathEditorModal(props) {
  const dispatch = useDispatch();
  const soalEditorStore = useSelector((state) => state.soalEditor);
  const mathFieldRef = useRef();
  const [latex, setLatex] = useState("");

  const writeLatex = (val) => {
    mathFieldRef.current.write(val).focus();
  };

  const closeModal = () => {
    dispatch(closeMathEditor());
  };

  const okHandler = () => {
    let range = soalEditorStore.focusRange;
    if (!range) return;
    setTimeout(() => {
      let frag = document.createDocumentFragment();
      let mathBox = document.createElement("span");
      mathBox.contentEditable = false;
      mathBox.classList.add("mathbox");
      katex.render(latex, mathBox, { throwOnError: false });
      frag.appendChild(document.createTextNode("\u00A0"));
      frag.appendChild(mathBox);
      frag.appendChild(document.createTextNode("\u00A0"));

      if (window.getSelection) {
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        let newRange = sel.getRangeAt(0);
        newRange.deleteContents();
        newRange.insertNode(frag);
        sel.collapseToEnd();
      } else if (document.selection && range.select) {
        let newRange = range.select();
        newRange.pasteHTML(frag);
      }
    });

    setLatex("");
    closeModal();
  };

  return (
    <Modal
      visible={soalEditorStore.mathEditorModal.visible}
      width={700}
      onOk={okHandler}
      onCancel={closeModal}
    >
      <Row gutter={10}>
        <Col>
          <Title level={1}>
            <FunctionOutlined />
          </Title>
        </Col>
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>
            Editor Persamaan
          </Title>
          <Text type="secondary">
            Gunakan tombol di bawah atau ketik persamaan menggunakan LaTeX
          </Text>
        </Col>
      </Row>
      <Divider />
      <div>
        <EditableMathField
          latex={latex}
          mathquillDidMount={(mathField) => (mathFieldRef.current = mathField)}
          onChange={(mathField) => setLatex(mathField.latex())}
          style={{
            width: "100%",
            border: "1px solid #dfdfdf",
            borderRadius: "8px",
            fontSize: "1.5rem",
            padding: "3rem 1rem",
          }}
        />
      </div>
      <Divider />
      <div style={{ overflowX: "scroll" }}>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            gap: 36,
            paddingBottom: 25,
          }}
        >
          {latexButtons.map((group, index) => (
            <div
              key={index}
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${group.buttonPerRow}, auto)`,
                gap: "5px",
              }}
            >
              {group.buttons.map((btn, index) => (
                <MathEditorButton
                  label={btn.label}
                  latex={btn.latex}
                  onClick={writeLatex}
                  key={index}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
