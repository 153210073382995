import { DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Skeleton,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ujianService from "../services/api/ujian";

const { Title, Text } = Typography;

export default function HasilUjian() {
  const [hasilUjian, setHasilUjian] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id: ujianUUID } = useParams();

  const columns = useMemo(() => {
    return [
      {
        title: "#",
        dataIndex: "no_induk",
        render: (data, record, index) => ++index,
      },
      {
        title: "NIS",
        dataIndex: "no_induk",
        filterSearch: true,
        sorter: (a, b) => a.no_induk > b.no_induk,
      },
      {
        title: "NAMA SISWA",
        dataIndex: "nama",
        filterSearch: true,
        sorter: (a, b) => a.nama > b.nama,
        fixed: "left",
      },
      {
        title: "STATUS UJIAN",
        dataIndex: "status_ujian",
        filterSearch: true,
        sorter: (a, b) => a.status_ujian > b.status_ujian,
        render: (data) => data ?? "-",
      },
      {
        title: "WAKTU UJIAN",
        dataIndex: "waktu_ujian",
        filterSearch: true,
        sorter: (a, b) => a.waktu_ujian > b.waktu_ujian,
        render: (data) => data ?? "-",
      },
      {
        title: "JML SOAL",
        render: () =>
          hasilUjian && hasilUjian.jumlah_soal ? hasilUjian.jumlah_soal : null,
      },
      {
        title: "JML BENAR",
        dataIndex: "jawaban_benar",
        filterSearch: true,
        sorter: (a, b) => a.jawaban_benar > b.jawaban_benar,
        render: (data) => data ?? "-",
      },
      {
        title: "SKOR UJIAN",
        dataIndex: "skor_ujian",
        filterSearch: true,
        sorter: (a, b) => a.skor_ujian > b.skor_ujian,
        render: (data) => data ?? "-",
      },
      // {
      //   title: "SKOR REMED 1",
      //   sorter: (a, b) => a.skor > b.skor,
      //   render: (data) => data ?? "-",
      // },
      // {
      //   title: "SKOR REMED 2",
      //   sorter: (a, b) => a.skor > b.skor,
      //   render: (data) => data ?? "-",
      // },
      {
        title: "STATUS LULUS",
        dataIndex: "status_kelulusan",
        filterSearch: true,
        sorter: (a, b) => a.status_ujian > b.status_ujian,
        render: (data) => {
          if (!data) return null;
          switch (data) {
            case "L":
              return <Tag color="green">LULUS</Tag>;
            case "TL":
              return <Tag color="red">TIDAK LULUS</Tag>;
            default:
              return <Tag color="yellow">BELUM LULUS</Tag>;
          }
        },
      },
    ];
  }, [hasilUjian]);

  const fetchHasilUjian = useCallback(async () => {
    setLoading(true);
    try {
      let res = await ujianService.getHasilUjian(ujianUUID);
      setHasilUjian(res.data.results);
      console.log(res.data.results);
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  }, [ujianUUID]);

  const downloadExcelHandler = async () => {
    let res = await ujianService.exportHasilUjian(ujianUUID);
    console.log(res.data.file_url);
    window.open(res.data.file_url);
  };

  useEffect(() => {
    fetchHasilUjian();
  }, [fetchHasilUjian]);

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24}>
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          style={{ padding: "1.5rem" }}
        >
          <Row justify="space-between">
            <Col>
              <Title level={4} style={{ marginBottom: "0.3rem" }}>
                Hasil Ujian
              </Title>
              {!loading && hasilUjian ? (
                <Text type="secondary">{hasilUjian.nama_ujian}</Text>
              ) : (
                <Skeleton.Input />
              )}
            </Col>
            <Col>
              {!loading && hasilUjian ? (
                <Button
                  onClick={downloadExcelHandler}
                  style={{ float: "right" }}
                  disabled={!hasilUjian.rombel || hasilUjian.rombel.length <= 0}
                  icon={<DownloadOutlined />}
                  type="primary"
                >
                  Unduh Excel
                </Button>
              ) : (
                <Skeleton.Input />
              )}
            </Col>
          </Row>
          <div style={{ marginTop: "2rem" }}>
            {hasilUjian && hasilUjian.rombel && hasilUjian.rombel.length > 0 ? (
              <Tabs defaultActiveKey="1" onChange={() => {}}>
                {hasilUjian.rombel.map((rombel, index) => (
                  <Tabs.TabPane tab={rombel.nama_rombel} key={index}>
                    <div className="table-responsive">
                      <Table
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={rombel.hasil_ujian}
                        loading={false}
                        pagination={{
                          pageSize: 100,
                        }}
                      />
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"Tidak ada data ujian"}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
}
