import { Modal, Form, Button, Table, Tag } from "antd";
import React, { useState, forwardRef , useImperativeHandle, useRef } from "react";
import anggotaRombelService from "../../../services/api/anggotaRombel";
import { PlusSquareOutlined } from "@ant-design/icons";
import ImportAnggotaRombelModal from "./ImportAnggotaRombelModal";

const DataAnggotaRombelModal = forwardRef((props, ref) => {
  const [ visible, setVisible ] = useState(false);
  const [ dataAnggotaRombel, setDataAnggotaRombel ] = useState([])
  const [ rombel, setRombel ] = useState({})
  const [ loadingTable, setLoadingTable ] = useState(false)
  const formRef = useRef();
  const importAnggotaRombelModalRef = useRef();

  const getDataAnggotaRombel = async (params = {}) => {
    const res = await anggotaRombelService.getDataAnggotaRombel(params)
    setDataAnggotaRombel(res.data.results)
    setLoadingTable(false)
  }

  const importSiswaHandler = () => {
    importAnggotaRombelModalRef.current.setVisible(true)
    importAnggotaRombelModalRef.current.setup()
  }

  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean)
    },
    getDataAnggotaRombel(id) {
      setDataAnggotaRombel([])
      setLoadingTable(true)
      getDataAnggotaRombel({ rombel_id: id })
      setRombel({
        rombel_id: id
      })
    }
  }))

  const columns = [
    {
      title: "Nama Siswa",
      dataIndex: ["peserta_didik", "nama"],
      sorter: true,
    },
    {
      title: "No Induk",
      dataIndex: ["peserta_didik", "no_induk"],
      sorter: true,
    },
    {
      title: 'NISN',
      dataIndex: ["peserta_didik", "nisn"],
      sorter: true,
    },
    {
      title: 'NIK',
      dataIndex: ["peserta_didik", "nik"],
      sorter: true,
    },
    {
      title: 'Jenis Kelamin',
      dataIndex: ["peserta_didik", 'jenis_kelamin'],
      sorter: true,
      render: (text, record) => {
        if (record.jenis_kelamin === "L") {
          return <Tag color="blue">Laki - laki</Tag>
        } else if(record.jenis_kelamin === "P") {
          return <Tag color="gold">Perempuan</Tag>
        } else {
          return <Tag color="red">Belum diisi</Tag>
        }
      },
    },
  ];

  return (
    <>
      <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={false}
          width={1400}
        >
          <Form
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            ref={formRef}
          >
            <div style={{ diplay: "flex", justifyContent: "space-between" }}>
              <h3>Data Siswa</h3>
            </div>
            <Button type="primary" ghost icon={<PlusSquareOutlined />} onClick={importSiswaHandler}>Tambah</Button>
            <Table
              loading={loadingTable}
              rowKey={(record) => record.peserta_didik_id}
              dataSource={dataAnggotaRombel}
              columns={columns}
            />
          </Form>
      </Modal>
      <ImportAnggotaRombelModal ref={importAnggotaRombelModalRef} rombel={rombel} onSuccess={() => {
        setLoadingTable(true)
        getDataAnggotaRombel({rombel_id: rombel.rombel_id})
      }} />
    </>
  )
})

export default DataAnggotaRombelModal;