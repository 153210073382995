import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from 'qs';

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const anggotaRombelService = {
  getDataAnggotaRombel: async (params) => {
    const endpoint = `${API_URL}/anggota-rombel?${qs.stringify(params)}`;
    return await axios.get(endpoint, headers);
  },
  postDataAnggotaRombel: async (data) => {
    const endpoint = `${API_URL}/anggota-rombel/import-excel`;
    return await axios.post(endpoint, data, headers);
  },
  importData: async (data) => {
    const endpoint = `${API_URL}/anggota-rombel/import-excel`;
    return await axios.post(endpoint, data, headers);
  }
}

export default anggotaRombelService;