import { Table } from "antd";
import React from "react";

export default function KDTable(props) {
  const { kompetensiDasar, loading } = props;

  const columns = [
    {
      title: "#",
      dataIndex: "rowIndex",
      sorter: (a, b) => a.rowIndex - b.rowIndex,
    },
    {
      title: "Kompetensi Dasar",
      dataIndex: "kompetensi_dasar",
      filterSearch: true,
      sorter: (a, b) => a.kompetensi_dasar.length - b.kompetensi_dasar.length,
    },
  ];

  return (
    <div className="table-responsive">
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={kompetensiDasar}
        pagination={{ defaultPageSize: 25, showSizeChanger: true }}
        loading={loading}
      />
    </div>
  );
}
