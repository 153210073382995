import Main from "../components/layout/Main";
import Dashboard from "../pages/Dashboard";
import DataUjian from "../pages/DataUjian";
import ForgotPassword from "../pages/ForgotPassword";
import Guru from "../pages/Guru";
import HasilUjian from "../pages/HasilUjian";
import Home from "../pages/Home";
import KompetensiDasar from "../pages/KompetensiDasar";
import LoginAdmin from "../pages/LoginAdmin";
import LoginGuru from "../pages/LoginGuru";
import LoginSekolah from "../pages/LoginSekolah";
import PaketUjian from "../pages/PaketUjian";
import PaketUjianEditor from "../pages/PaketUjianEditor";
import RemedialUjianEditor from "../pages/RemedialUjianEditor";
import PesertaDidik from "../pages/PesertaDidik";
import ResetPassword from "../pages/ResetPassword";
import Rombel from "../pages/rombel/index";
import Sekolah from "../pages/Sekolah";
import Soal from "../pages/Soal";

const routes = [
  {
    path: "/login",
    component: LoginGuru,
    exact: true,
    middleware: {
      protected: true,
      type: "guest",
    },
  },
  {
    path: "/login/admin",
    component: LoginAdmin,
    exact: true,
    middleware: {
      protected: true,
      type: "guest",
    },
  },
  {
    path: "/login/sekolah",
    component: LoginSekolah,
    exact: true,
    middleware: {
      protected: true,
      type: "guest",
    },
  },
  {
    path: "/login/guru",
    component: LoginGuru,
    exact: true,
    middleware: {
      protected: true,
      type: "guest",
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    exact: false,
  },
  {
    component: Main,
    children: [
      {
        path: "/",
        component: Home,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
        },
      },
      {
        path: "/dashboard",
        component: Dashboard,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin"],
        },
      },
      {
        path: "/peserta-didik",
        component: PesertaDidik,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin", "sekolah"],
        },
      },
      {
        path: "/guru",
        component: Guru,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin", "sekolah", "guru"],
        },
      },
      {
        path: "/data-ujian",
        component: DataUjian,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin", "sekolah"],
        },
      },
      {
        path: "/rombel",
        component: Rombel,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin", "sekolah"],
        },
      },
      {
        path: "/sekolah",
        component: Sekolah,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin"],
        },
      },
      {
        path: "/kompetensi-dasar",
        component: KompetensiDasar,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["guru"],
        },
      },
      {
        path: "/soal",
        component: Soal,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin", "guru"],
        },
      },
      {
        path: "/paket-ujian",
        component: PaketUjian,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["admin", "guru"],
        },
      },
      {
        path: "/paket-ujian/buat-ujian",
        component: PaketUjianEditor,
        exact: true,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["guru"],
        },
      },
      {
        path: "/paket-ujian/:id/hasil",
        component: HasilUjian,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["guru"],
        },
      },
      {
        path: "/paket-ujian/:id/remedial/buat-remedial",
        component: RemedialUjianEditor,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["guru"],
        },
      },
      {
        path: "/paket-ujian/:id/remedial/:remedId",
        component: RemedialUjianEditor,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["guru"],
        },
      },
      {
        path: "/paket-ujian/:id",
        component: PaketUjianEditor,
        middleware: {
          protected: true,
          type: "auth",
          accept: ["guru"],
        },
      },
    ],
  },
];
export default routes;
