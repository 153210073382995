import axios from "axios";
import { API_URL } from "../../utils/config";
import qs from "qs";

const getHeaders = () => {
  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

const getDataUjian = async (params, config = {}) => {
  let endpoint = `${API_URL}/ujian?${qs.stringify(params)}`;
  return await axios.get(endpoint, { ...getHeaders(), ...config });
};

const getHasilUjian = async (uuid, config = {}) => {
  let endpoint = `${API_URL}/ujian/get-hasil?ujian_id=${uuid}`;
  return await axios.get(endpoint, { ...getHeaders(), ...config });
};

const exportHasilUjian = async (uuid, config = {}) => {
  let endpoint = `${API_URL}/ujian/export-hasil?ujian_id=${uuid}`;
  return await axios.get(endpoint, { ...getHeaders(), ...config });
};

const getDataRombel = async (params) => {
  let endpoint = `${API_URL}/ujian/get-rombel?${qs.stringify(params)}`;
  return await axios.get(endpoint, { ...getHeaders() });
};

const getUjianByUUID = async (uuid) => {
  let endpoint = `${API_URL}/ujian/get-by-uuid/${uuid}`;
  return await axios.get(endpoint, { ...getHeaders() });
};

const getSoalByIds = async (soalId) => {
  let endpoint = `${API_URL}/ujian/get-soal-array`;
  return await axios.post(endpoint, { soal_id: soalId }, { ...getHeaders() });
};

const postUjian = async (payload) => {
  let endpoint = `${API_URL}/ujian`;
  return await axios.post(endpoint, payload, { ...getHeaders() });
};

const updateUjian = async (payload, id) => {
  let endpoint = `${API_URL}/ujian/${id}`;
  return await axios.put(endpoint, payload, { ...getHeaders() });
};

const updateStatusUjian = async (payload, id) => {
  let endpoint = `${API_URL}/ujian/update-status/${id}`;
  return await axios.put(endpoint, payload, { ...getHeaders() });
};

const getCurrentDataUjian = async (params, config) => {
  let endpoint = `${API_URL}/data-ujian?${qs.stringify(params)}`;
  return await axios.get(endpoint, { ...getHeaders(), ...config });
};

const ujianService = {
  getDataUjian,
  getDataRombel,
  getSoalByIds,
  postUjian,
  getUjianByUUID,
  updateUjian,
  getHasilUjian,
  exportHasilUjian,
  updateStatusUjian,
  getCurrentDataUjian,
};

export default ujianService;
