import { createSlice } from "@reduxjs/toolkit";

const paketUjian = createSlice({
  name: "paket-ujian",
  initialState: {
    dataUjian: [],
    dataRombel: [],
  },
  reducers: {
    setDataUjian: (state, action) => {
      return {
        ...state,
        dataUjian: action.payload,
      };
    },
    setDataRombel: (state, action) => {
      return {
        ...state,
        dataRombel: action.payload,
      };
    },
  },
});

export const { setDataUjian, setDataRombel } = paketUjian.actions;
export default paketUjian.reducer;
