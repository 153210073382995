import { Card } from "antd";
import { useSelector } from "react-redux";

function Home() {
  const user = useSelector(state => state.authUser.user);
  return (
    <Card>
      <p>Selamat datang <span style={{ fontWeight: "bold" }}>{user?.nama}</span></p>
    </Card>
  );
}

export default Home;
