import { Card, Typography } from "antd"

function LoaderAuth() {
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100vh", alignItems: "center" }}>
      <Typography.Title level={4}>Waiting for Authentication...</Typography.Title>
    </div>
  )
}

export default LoaderAuth();