import {
  ClockCircleOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  message,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import ujianService from "../../services/api/ujian";
const { Text, Title } = Typography;

export default function CardUjian(props) {
  const { ujian } = props;

  console.log(ujian)

  const updateTampilSkorGuru = async (active) => {
    let payload = { tampil_skor_guru: active ? 1 : 0 };
    updateStatusUjian(payload);
  };

  const updateTampilSkorSiswa = async (active) => {
    let payload = { tampil_skor_siswa: active ? 1 : 0 };
    updateStatusUjian(payload);
  };

  const updateAktifSinkron = (active) => {
    let payload = { aktif_sinkron: active ? 1 : 0 };
    updateStatusUjian(payload);
  };

  const updatePublishUjian = async (active) => {
    let payload = { publish: active ? 1 : 0 };
    updateStatusUjian(payload);
  };

  const updateStatusUjian = async (payload) => {
    try {
      await ujianService.updateStatusUjian(payload, ujian.id);
      message.success("Berhasil diupdate!");
    } catch (error) {
      message.error("Terjadi kesalahan.");
    }
  };

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Row>
        <Col span={1} style={{ backgroundColor: "#8854C0" }}></Col>
        <Col span={23} style={{ padding: "1rem", paddingLeft: "2rem" }}>
          <Text style={{ marginBottom: 0 }}>PAKET SOAL</Text>
          <Title level={2} style={{ margin: "0.5rem 0" }}>
            {ujian.jenis_ujian_id == 1
              ? "US"
              : ujian.jenis_ujian_id == 2
              ? "PAT"
              : "PAS"}{" "}
            {ujian.nama_ujian}
          </Title>
          <div style={{ marginBottom: "1rem" }}>
            <Space size={24}>
              <Space>
                <span>Tanggal Ujian : {ujian.tgl_ujian}</span>
              </Space>
              <Space>
                <span>Waktu : {ujian.waktu_mulai} - { ujian.waktu_akhir }</span>
              </Space>
              {/* <Space>
                Kelas :<b>{ujian.token_khusus}</b>
              </Space> */}
            </Space>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Space size={24}>
              <Space>
                <UnorderedListOutlined />
                <span>{ujian.soal_json.length} Soal</span>
              </Space>
              <Space>
                <ClockCircleOutlined />
                <span>{ujian.durasi_ujian} Menit</span>
              </Space>
              <Space>
                Token Uji Coba Soal :<b>{ujian.token_khusus}</b>
              </Space>
            </Space>
          </div>
          {/* <div>
            <Space size={24}>
              <label>
                <Switch
                  style={{ marginRight: "0.5rem" }}
                  onChange={updateTampilSkorSiswa}
                  defaultChecked={ujian.tampil_skor_siswa}
                />
                <span>Tampil Skor Siswa</span>
              </label>
              <label>
                <Switch
                  style={{ marginRight: "0.5rem" }}
                  onChange={updateTampilSkorGuru}
                  defaultChecked={ujian.tampil_skor_guru}
                />
                <span>Tampil Skor Guru</span>
              </label>
              <label>
                <Switch
                  style={{ marginRight: "0.5rem" }}
                  onChange={updateAktifSinkron}
                  defaultChecked={ujian.aktif_sinkron}
                />
                <span>Aktif Sinkron</span>
              </label>
              <label>
                <Switch
                  style={{ marginRight: "0.5rem" }}
                  onChange={updatePublishUjian}
                  defaultChecked={ujian.publish}
                />
                <span>Publish</span>
              </label>
            </Space>
          </div> */}
          <Divider style={{ margin: "0.8rem 0" }} />
          <Space>
            <NavLink to={`/paket-ujian/${ujian.ujian_id}`}>
              {ujian.is_used === 1 ? (
                <Button icon={<UnorderedListOutlined />}>Detail</Button>
              ) : (
                <Button icon={<EditOutlined />}>Edit</Button>
              )}
            </NavLink>
            <NavLink to={`/paket-ujian/${ujian.ujian_id}/hasil`}>
              <Button icon={<UnorderedListOutlined />}>
                Lihat Hasil Ujian
              </Button>
            </NavLink>
          </Space>
        </Col>
      </Row>
    </Card>
  );
}
