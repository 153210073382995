import { Form, Select, Input, Modal, Button } from "antd"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import semesterService from "../../../services/api/semester";
import mataPelajaranService from "../../../services/api/mataPelajaran";
import kelompokService from "../../../services/api/kelompok";
import pembelajaranService from "../../../services/api/pembelajaran";
import { setData as setGlobalDataSemester } from "../../../store/features/semester";
import { setData as setGlobalDataGuru } from "../../../store/features/guru";
import { setData as setGlobalDataMataPelajaran } from "../../../store/features/mata-pelajaran";
import { setData as setGlobalDataKelompok } from "../../../store/features/kelompok";
import { openNotificationWithIcon } from "../../../components/notification/customNotification";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import guruService from "../../../services/api/guru";

function PembelajaranCreateForm(props, ref) {
  // Component
  const { Option } = Select;
  // Global State
  const [visible, setVisible] = useState(false);
  const semester = useSelector(state => state.semester);
  const guru = useSelector(state => state.guru);
  const mataPelajaran = useSelector(state => state.mataPelajaran);
  const kelompok = useSelector(state => state.kelompok);
  const dispatch = useDispatch()
  // Local State
  const [dataSemester, setDataSemester] = useState([]);
  const [dataGuru, setDataGuru] = useState([]);
  const [dataMataPelajaran, setDataMataPelajaran] = useState([]);
  const [dataKelompok, setDataKelompok] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState({})
  const formRef = useRef();
  // Methods
  const formDataHandler = (object = {}) => {
    const data = new FormData();
    for (const key in object) if (object[key]) data.append(key, object[key]);
    return data;
  }

  const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const clearForm = () => {
    setError({});
    formRef.current.resetFields();
  }

  const getDataSemester = async (params = {}, signal = (new AbortController()).signal) => {
    return await semesterService.getDataSemester(params, signal);
  };

  const getDataGuru = async (params = {}, signal = (new AbortController()).signal) => {
    return await guruService.getDataGuru(params);
  };

  const getDataMataPelajaran = async (params = {}, signal = (new AbortController()).signal) => {
    return await mataPelajaranService.getDataMataPelajaran(params, signal);
  };

  const getDataKelompok = async (params = {}, signal = (new AbortController()).signal) => {
    return await kelompokService.getDataKelompok(params, signal);
  };

  const postDataPembelajaran = async (values) => {
    if(props?.detailSekolah?.sekolah_id) values.sekolah_id = props.detailSekolah.sekolah_id;
    if(props?.detailSekolah?.id) values.rombongan_belajar_id = props.detailSekolah.id;
    setLoadingButton(true);
    const data = formDataHandler(values);
    try {
      setError({});
      await pembelajaranService.postDataPembelajaran(data);
      setVisible(false);
      openNotificationWithIcon("Berhasil", "Data baru berhasil ditambahkan", <CheckCircleOutlined style={{ color: "#52c41a" }} />)
      clearForm();
      props.getDataPembelajaran()
    } catch (error) {
      if (error.response.status === 422) {
        setError(error.response.data.errors);
        openNotificationWithIcon("Peringatan", "Silahkan cek kembali formulir anda", <WarningOutlined style={{ color: "yellow" }} />);
      } else {
        setVisible(false);
        openNotificationWithIcon("Internal Server Error", "Server sedang bermasalah, silahkan coba beberapa saat lagi", <WarningOutlined style={{ color: '#ff0000' }} />);
      }
    } finally {
      setLoadingButton(false);
    }
  }

  useImperativeHandle(ref, () => ({
    setVisible(boolean) {
      setVisible(boolean)
    },
    setup() {
      getDataSemester({ limit: 10, offset: 0 }, semester.controller.signal).then(res => dispatch(setGlobalDataSemester(res.data)));
      getDataGuru({}, guru.controller.signal).then(res => dispatch(setGlobalDataGuru(res.data)));
      getDataMataPelajaran({ limit: 10, offset: 0 }, mataPelajaran.controller.signal).then(res => dispatch(setGlobalDataMataPelajaran(res.data)));
      getDataKelompok({ limit: 10, offset: 0 }, kelompok.controller.signal).then(res => dispatch(setGlobalDataKelompok(res.data)));
    }
  }))

  useEffect(() => {
    setDataSemester(semester.data.results)
    return () => { semester.controller.abort() }
  }, [semester])

  useEffect(() => {
    setDataGuru(guru.data.results)
    return () => { guru.controller.abort() }
  }, [guru])

  useEffect(() => {
    setDataMataPelajaran(mataPelajaran.data.results)
    return () => { mataPelajaran.controller.abort() }
  }, [mataPelajaran])

  useEffect(() => {
    setDataKelompok(kelompok.data.results)
    return () => { kelompok.controller.abort() }
  }, [kelompok])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
        width={900}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={postDataPembelajaran}
          ref={formRef}
        >
          <div style={{ diplay: "flex", justifyContent: "space-between" }}>
            <h3>Buat Data</h3>
          </div>
          <Form.Item label="Semester" name="semester_id" validateStatus={error.semester_id ? "error" : null} help={error.semester_id ? error.semester_id[0] : null}>
            <Select
              showSearch
              placeholder="Pilih Semester"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataSemester({ search: keyword, limit: 10, offset: 0 }).then(res => setDataSemester(res.data.results))}
            >
              {dataSemester.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Guru" name="guru_id" validateStatus={error.guru_id ? "error" : null} help={error.guru_id ? error.guru_id[0] : null}>
            <Select
              showSearch
              placeholder="Pilih Guru"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataGuru({ search: keyword, limit: 10, offset: 0 }).then(res => setDataGuru(res.data.results))}
            >
              {dataGuru.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Guru Pengajar" name="guru_pengajar_id" validateStatus={error.guru_id ? "error" : null} help={error.guru_id ? error.guru_id[0] : null}>
            <Select
              showSearch
              placeholder="Pilih Guru Pengajar"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataGuru({ search: keyword, limit: 10, offset: 0 }).then(res => setDataGuru(res.data.results))}
            >
              {dataGuru.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Mata Pelajaran" name="mata_pelajaran_id" validateStatus={error.mata_pelajaran_id ? "error" : null} help={error.mata_pelajaran_id ? error.mata_pelajaran_id[0] : null}>
            <Select
              showSearch
              placeholder="Pilih Mata Pelajaran"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataMataPelajaran({ search: keyword, limit: 10, offset: 0 }).then(res => setDataMataPelajaran(res.data.results))}
            >
              {dataMataPelajaran.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Nama Mata Pelajaran" hasFeedback name="nama_mata_pelajaran" validateStatus={error.nama_mata_pelajaran ? "error" : null} help={error.nama_mata_pelajaran ? error.nama_mata_pelajaran[0] : null}>
            <Input placeholder="Ilmu Pengetahuan Alam (IPA)" />
          </Form.Item>
          <Form.Item label="Kelompok" name="kelompok_id" validateStatus={error.kelompok_id ? "error" : null} help={error.kelompok_id ? error.kelompok_id[0] : null}>
            <Select
              showSearch
              placeholder="Pilih Kelompok"
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {dataKelompok.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.nama_kelompok}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="No Urut" hasFeedback name="no_urut" validateStatus={error.no_urut ? "error" : null} help={error.no_urut ? error.no_urut[0] : null}>
            <Input placeholder="1" type="number" />
          </Form.Item>
          <Form.Item label="KKM" hasFeedback name="kkm" validateStatus={error.kkm ? "error" : null} help={error.kkm ? error.kkm[0] : null}>
            <Input placeholder="1" type="number" />
          </Form.Item>
          <Form.Item label="Rasio P" hasFeedback name="rasio_p" validateStatus={error.rasio_p ? "error" : null} help={error.rasio_p ? error.rasio_p[0] : null}>
            <Input placeholder="1" type="number" />
          </Form.Item>
          <Form.Item label="Rasio K" hasFeedback name="rasio_k" validateStatus={error.rasio_k ? "error" : null} help={error.rasio_k ? error.rasio_k[0] : null}>
            <Input placeholder="1" type="number" />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: ".5rem", marginTop: ".5rem" }}>
            <Button key="back" onClick={() => setError({}) || setVisible(false)}>
              Batal
            </Button>
            <Button htmlType="submit" key="submit" type="primary" loading={loadingButton}>
              Simpan
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default forwardRef(PembelajaranCreateForm);