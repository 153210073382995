import IconDashboard from "../../components/icons/IconDashboard";
import IconGuru from "../../components/icons/IconGuru";
import IconPesertaDidik from "../../components/icons/IconPesertaDidik";
import IconSignIn from "../../components/icons/IconSignIn";

const navigations = [
  {
    // name: "Data",
    items: [
      {
        label: "Dashboard",
        path: "/dashboard",
        icon: <IconDashboard />,
        middleware: {
          accept: ["admin"],
        },
      },
      {
        label: "Home",
        path: "/",
        icon: <IconGuru />,
      },
      {
        label: "Sekolah",
        path: "/sekolah",
        icon: <IconGuru />,
        middleware: {
          accept: ["admin"],
        },
      },
      {
        label: "Guru",
        path: "/guru",
        icon: <IconGuru />,
        middleware: {
          accept: ["admin", "sekolah"],
        },
      },
      {
        label: "Peserta Didik",
        path: "/peserta-didik",
        icon: <IconPesertaDidik />,
        middleware: {
          accept: ["admin", "sekolah"],
        },
      },
      {
        label: "Rombel",
        path: "/rombel",
        icon: <IconPesertaDidik />,
        middleware: {
          accept: ["admin", "sekolah"],
        },
      },
      {
        label: "Data Ujian",
        path: "/data-ujian",
        icon: <IconPesertaDidik />,
        middleware: {
          accept: ["admin", "sekolah"],
        },
      },
      {
        label: "Kompetensi Dasar",
        path: "/kompetensi-dasar",
        icon: <IconSignIn />,
        middleware: {
          accept: ["guru"],
        },
      },
      {
        label: "Soal",
        path: "/soal",
        icon: <IconSignIn />,
        middleware: {
          accept: ["admin", "guru"],
        },
      },
      {
        label: "Paket Ujian",
        path: "/paket-ujian",
        icon: <IconSignIn />,
        middleware: {
          accept: ["admin", "guru"],
        },
      },
    ],
  },
];

export default navigations;
