import { DeleteFilled, PictureFilled } from "@ant-design/icons";
import { Button, Image, message, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import Dragger from "antd/lib/upload/Dragger";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeImageUploader } from "../../store/features/soal-editor";
import { getBase64 } from "../../utils/helpers";

export default function ImageUploaderModal() {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState(null);
  const { imageUploaderModal } = useSelector((state) => state.soalEditor);

  const uploadChangeHandler = ({ fileList: newFileList, file }) => {
    if (!file) return;
    const isImage = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isImage) return message.error("File harus berupa gambar (jpg/png)");
    if (!isLt2M) return message.error("File tidak boleh lebih dari 2MB!");
    return setFileList(newFileList);
  };

  useEffect(() => {
    const file = fileList[0];
    if (file && file.originFileObj) {
      getBase64(file.originFileObj, (img) => setImage(img));
    } else {
      setImage(null);
    }
  }, [fileList]);

  const saveImageHandler = useCallback(() => {
    if (fileList[0]) {
      imageUploaderModal.onSave(image);
    } else if (!image && imageUploaderModal.image) {
      imageUploaderModal.onSave(null);
    }
    dispatch(closeImageUploader());
    removeImage();
  }, [fileList, image, dispatch, imageUploaderModal]);

  const removeImage = () => setFileList([]);

  const cancelHandler = () => {
    dispatch(closeImageUploader());
    removeImage();
  };

  useEffect(() => {
    const file = imageUploaderModal.image;
    setImage(file);
  }, [imageUploaderModal]);

  return (
    <Modal
      visible={imageUploaderModal.visible}
      title={<Title level={5}>Sisipkan Gambar</Title>}
      onOk={saveImageHandler}
      onCancel={cancelHandler}
    >
      {!image ? (
        <Dragger
          name="file"
          multiple={false}
          fileList={fileList}
          showUploadList={false}
          beforeUpload={() => false}
          accept={"image/png, image/jpeg"}
          onChange={uploadChangeHandler}
        >
          <p className="ant-upload-drag-icon">
            <PictureFilled />
          </p>
          <p className="ant-upload-hint">
            Seret dan lepas gambar atau klik disini untuk mengunggah
          </p>
        </Dragger>
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <Button
            type="primary"
            icon={<DeleteFilled />}
            onClick={removeImage}
            style={{
              position: "absolute",
              top: "0.75rem",
              right: "0.75rem",
              zIndex: "99",
            }}
          />
          <Image
            src={image}
            style={{ width: "100%", minHeight: "250px" }}
            alt="Preview"
          />
        </div>
      )}
    </Modal>
  );
}
