import {
  CheckOutlined,
  DeleteOutlined,
  EditFilled,
  FontSizeOutlined,
  FunctionOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
} from "antd";
import React, { useRef } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import {
  openImageUploader,
  openMathEditor,
  setFocusRange,
} from "../../store/features/soal-editor";
import styles from "./SoalEditor.module.css";

export default function FormOpsi(props) {
  const dispatch = useDispatch();
  const { opsi, setOpsi, opsiIndex, onDelete } = props;
  const { isMultipleAnswer, MIN_OPSI } = useSelector(
    (state) => state.soalEditor
  );
  const editorRef = useRef();

  const setCurrentOpsi = (payload) => {
    setOpsi((prev) => {
      let items = [...prev];
      let changed = { ...items[opsiIndex], ...payload };
      items[opsiIndex] = changed;
      return items;
    });
  };

  const openMathEditorHandler = () => {
    editorRef.current.focus();
    dispatch(openMathEditor());
  };

  const openImageUploaderHandler = () => {
    dispatch(
      openImageUploader({
        image: opsi[opsiIndex].gambar,
        onSave: (image) => {
          setCurrentOpsi({ teks: "", gambar: image });
        },
      })
    );
  };

  const editorChangeHandler = (e) => {
    let val = e.target.value;
    let content = val === "<div><br></div>" || val === "<br>" ? "" : val;
    setCurrentOpsi({ teks: content });
  };

  const pasteHandler = (e) => {
    var clipboardData, pastedData;
    e.stopPropagation();
    e.preventDefault();
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData("Text");
    window.document.execCommand("insertText", false, pastedData);
  };

  const setCorrectAnswer = (e) => {
    if (isMultipleAnswer) {
      setCurrentOpsi({ jawaban_benar: !opsi[opsiIndex].jawaban_benar });
    } else {
      setOpsi((opsi) =>
        opsi.map((o, index) => ({
          ...o,
          jawaban_benar: index !== opsiIndex ? false : true,
        }))
      );
    }
  };

  return (
    <Card className={styles["opsi-card"]}>
      <Row
        justify="space-between"
        align="middle"
        className={styles["opsi-tools"]}
      >
        <Col>
          <Space>
            <Tooltip title={"Hapus Opsi"}>
              <Button
                disabled={opsi.length <= MIN_OPSI}
                icon={<DeleteOutlined />}
                onClick={() => onDelete(opsiIndex)}
              />
            </Tooltip>
            <Divider type="vertical" />
            {!opsi[opsiIndex].gambar ? (
              <Tooltip title={"Ganti ke gambar"}>
                <Button
                  icon={<PictureOutlined />}
                  onClick={openImageUploaderHandler}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Ganti ke text"}>
                <Button
                  icon={<FontSizeOutlined />}
                  onClick={() => setCurrentOpsi({ gambar: null })}
                />
              </Tooltip>
            )}
            <Tooltip title={"Sisipkan Persamaan"}>
              <Button
                icon={<FunctionOutlined />}
                onClick={openMathEditorHandler}
              />
            </Tooltip>
          </Space>
        </Col>
        <Col>
          <Tooltip title={"Tandai jawaban benar"}>
            {isMultipleAnswer ? (
              <Checkbox
                checked={opsi[opsiIndex].jawaban_benar}
                onChange={setCorrectAnswer}
                className={styles["opsi-checkbox"]}
              />
            ) : (
              <Button
                className="check-correct-button"
                type={opsi[opsiIndex].jawaban_benar ? "primary" : "default"}
                icon={<CheckOutlined style={{ padding: 0 }} />}
                shape={"circle"}
                onClick={setCorrectAnswer}
              />
            )}
          </Tooltip>
        </Col>
      </Row>
      {opsi[opsiIndex].gambar ? (
        <div className={styles["opsi-image-container"]}>
          <div className={styles["opsi-image-tools"]}>
            <Button
              type="primary"
              icon={<EditFilled />}
              onClick={openImageUploaderHandler}
            />
          </div>
          <img
            src={opsi[opsiIndex].gambar}
            className={styles["opsi-image"]}
            draggable={false}
            alt=""
          />
        </div>
      ) : (
        <div
          className={styles["opsi-editor-wrapper"]}
          onClick={() => editorRef.current.focus()}
        >
          <ContentEditable
            html={opsi[opsiIndex].teks}
            innerRef={editorRef}
            className={`${styles["opsi-editor"]} math-appendable`}
            onChange={editorChangeHandler}
            onPaste={pasteHandler}
            onFocus={() => dispatch(setFocusRange())}
            onDrop={(e) => e.preventDefault()}
          />
        </div>
      )}
    </Card>
  );
}
