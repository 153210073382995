import { InboxOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import anggotaRombelService from "../../../services/api/anggotaRombel";
import semesterService from "../../../services/api/semester";
import { setData as setGlobalDataSemester } from "../../../store/features/semester";
import { API_URL } from "../../../utils/config";

const ImportAnggotaRombelModal = forwardRef((props, ref) => {
  const { onSuccess } = props;
  // Global State
  const appSetting = useSelector(state => state.appSetting);
  const semester = useSelector(state => state.semester);
  const dispatch = useDispatch();
  // Local State
  const [dataSemester, setDataSemester] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ visible: false });
  const [selectedSemester, setSelectedSemester] = useState(null);

  useImperativeHandle(ref, () => ({
    setVisible: (visible) => setVisible(visible),
      setup: () => getDataSemester({ limit: 10, offset: 0 }, semester.controller.signal).then(res => dispatch(setGlobalDataSemester(res.data)) && semester.controller.abort())
  }));

  const getDataSemester = async (params = {}, signal = (new AbortController()).signal) => {
    return await semesterService.getDataSemester(params, signal);
  }

  const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const clearForm = () => {
    setFileList([]);
    setSelectedSemester(null);
  }

  const uploadHandler = async () => {
    let hideLoadingToast = message.loading("Mengimport file...", 0);
    setLoading(true);
    setError({ visible: false });
    try {
      let formData = new FormData();
      formData.append("file", fileList[0]);
      formData.append('sekolah_id', appSetting.sekolah_id);
      formData.append('rombel_id', props.rombel.rombel_id);
      formData.append('semester_id', selectedSemester);
      let res = await anggotaRombelService.importData(formData);
      message.success(res.data.message);
      onSuccess();
      clearForm();
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error.response.data);
      errorHandler(error.response);
    } finally {
      hideLoadingToast();
      setLoading(false);
      setVisible(false);
    }
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    fileList,
    listType: "picture",
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList((files) => [...files, file]);
      return false;
    },
  };

  const errorHandler = (error) => {
    let visible = true;
    let message = "Terjadi kesalahan";
    let description = null;
    // Check if error is unprocessable content error
    if (error.status === 422) {
      message = error.data.message ?? message;
      description = (
        // Show lists of unprocessable contents
        <ul style={{ marginBottom: 0 }}>
          {Object.values(error.data.errors).map((error, index) => (
            <li key={index}>{error[0]}</li>
          ))}
        </ul>
      );
    }
    setError({ visible, message, description });
  };

  useEffect(() => {
    if(visible) setDataSemester(semester.data.results)
  }, [semester, visible])

  return (
    <Modal
      title="Import data anggota rombel"
      visible={visible}
      onOk={uploadHandler}
      onCancel={() => setVisible(false)}
      width={"50rem"}
      okText={"Import data"}
      cancelText={"Batal"}
      okButtonProps={{ disabled: fileList.length <= 0 || loading }}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Upload file" key="1">
          {error.visible && (
            <Alert
              message={error.message}
              description={error.description}
              type="error"
              closable
              style={{ marginBottom: 10 }}
              onClick={() => setError({ visible: false })}
            />
          )}
          <Select
              showSearch
              placeholder="Pilih Semester"
              optionFilterProp="children"
              filterOption={filterOption}
              onSearch={(keyword) => getDataSemester({ limit: 10, offset: 0, search: keyword }).then(res => setDataSemester(res.data.results))}
              style={{ width: "100%", marginBottom: 5 }}
              onSelect={(value) => setSelectedSemester(value)}
            >
              {dataSemester.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.nama}
                </Select.Option>
              ))}
          </Select>
          <Upload.Dragger
            {...draggerProps}
            style={{ display: fileList.length > 0 ? "none" : "block" }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Tarik file ke sini</p>
            <p className="ant-upload-hint">Atau, klik disini</p>
          </Upload.Dragger>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Panduan Import" key="2">
          <Row align="middle" justify="center">
            <Col style={{ padding: "2rem 0" }}>
              <Space direction="vertical" align="center">
                <Title level={5}>Langkah-langkah import data</Title>
                <ol>
                  <li>
                    <div>Unduh template excel dengan klik tombol dibawah</div>
                  </li>
                  <li>Isi file excel dengan data yang ingin diimport</li>
                  <li>Pastikan data yang diisi sudah benar</li>
                  <li>Upload file yang telah diisi</li>
                </ol>
                <a href={`${API_URL}/template/import-peserta-didik`}>
                  <Button>Unduh template</Button>
                </a>
              </Space>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
});

export default ImportAnggotaRombelModal;
