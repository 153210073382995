import { useHistory } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Select,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import SemesterService from "../services/api/semester";
import { setData as setGlobalDataSemester } from "../store/features/semester";

import { openNotificationWithIcon } from "../components/notification/customNotification";
import { WarningOutlined } from "@ant-design/icons";
import { setData as setDataAuthUser } from "../store/features/auth-user";
import authGuruService from "../services/auth/auth-guru";
import { setSekolahId, setSemesterId } from "../store/features/app-setting";

function LoginGuru() {
  // Component
  const { Title } = Typography;
  const { Content } = Layout;
  // Global State
  const semester = useSelector((state) => state.semester);
  const dispatch = useDispatch();
  // Local state
  const [dataSemester, setDataSemester] = useState([]);
  const [error, setError] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const history = useHistory();
  const formRef = useRef();

  // Methods
  const getDataSemester = async (
    params = {},
    signal = new AbortController().signal
  ) => {
    return await SemesterService.getDataSemester(params, signal);
  };

  const login = async (data) => {
    data.semester_id = 20241;
    setLoadingButton(true);
    try {
      const res = await authGuruService.login(data);
      localStorage.setItem("token", res.data.token);
      dispatch(setDataAuthUser(res.data.user));
      dispatch(setSekolahId(res.data.user.sekolah_id));
      dispatch(setSemesterId(res.data.semester_id));
      history.push("/");
    } catch (error) {
      if (error.response.status === 422) {
        setError(error.response.data.errors);
        openNotificationWithIcon(
          "Login Failed",
          "Please check your username and password",
          <WarningOutlined style={{ color: "yellow" }} />
        );
      } else if (error.response.status === 401) {
        setError(error.response.data.errors);
        openNotificationWithIcon(
          "Login Failed",
          "Invalid Credentials",
          <WarningOutlined style={{ color: "yellow" }} />
        );
      } else {
        openNotificationWithIcon(
          "Internal Server Error",
          "Server sedang dalam masalah",
          <WarningOutlined style={{ color: "#ff0000" }} />
        );
      }
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    const loginData = {
      email: "",
      password: "",
    };
    if (formRef.current) formRef.current.setFieldsValue(loginData);
    getDataSemester({ limit: 50, offset: 0 }, semester.controller.signal).then(
      (res) => {
        semester.controller.abort();
        dispatch(setGlobalDataSemester(res.data));
      }
    );
    return () => {};
  }, []);

  useEffect(() => {
    setDataSemester(semester.data.results);
  }, [semester, history]);

  const formItemProps = (key) => {
    return {
      name: key,
      validateStatus: error?.[key] ? "error" : null,
      help: error?.[key]?.[0],
    };
  };

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ minHeight: "100vh" }}
      >
        <Content className="signin">
          <Row
            gutter={[24, 0]}
            justify="space-around"
            style={{ marginTop: "3rem" }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Login Guru</Title>
              <Title className="font-regular text-muted" level={5}>
                Masukan Email dan Password untuk mengakses halaman Guru
              </Title>
              <Form
                onFinish={login}
                layout="vertical"
                className="row-col"
                ref={formRef}
              >
                {/* <Form.Item label="Semester" {...formItemProps("semester_id")}>
                  <Select placeholder="Semester">
                    {dataSemester.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nama}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                <Form.Item label="Email" {...formItemProps("email")}>
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item label="Password" {...formItemProps("password")}>
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loadingButton}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              {/* <img src={signinbg} alt="" /> */}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export default LoginGuru;
