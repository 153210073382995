import { API_URL } from "../../utils/config";
import axios from "axios";
import qs from 'qs';

const token = `Bearer ${localStorage.getItem('token')}`;

const headers = {
  headers: {
    authorization: token
  }
}

const jurusanService = {
  getDataJurusan: async (params) => {
    const endpoint = `${API_URL}/jurusan?${qs.stringify(params)}`;
    return await axios.get(endpoint, headers);
  },
}

export default jurusanService;