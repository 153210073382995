import { PlusSquareFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import kompetensiDasarService from "../../services/api/kompetensiDasar";
import soalService from "../../services/api/soal";
import {
  setEditorVisible,
  setIsMultipleAnswer,
  setLoading,
} from "../../store/features/soal-editor";
import FormOpsi from "./FormOpsi";
import FormSoal from "./FormSoal";
import ImageUploaderModal from "./ImageUploaderModal";
import MathEditorModal from "./MathEditorModal";
import styles from "./SoalEditor.module.css";
import SoalEditorToolbar from "./SoalEditorToolbar";

export default function SoalEditorModal(props) {
  const dispatch = useDispatch();
  const { onSuccess } = props;
  const { soalId, isMultipleAnswer, MAX_OPSI, MIN_OPSI, loading } = useSelector(
    (state) => state.soalEditor
  );
  const [soal, setSoal] = useState({
    teks: "",
    gambar: null,
    kompetensi_dasar_id: null,
    jenis_soal: 1,
  });
  const [opsi, setOpsi] = useState([
    { teks: "", gambar: null, jawaban_benar: false },
    { teks: "", gambar: null, jawaban_benar: false },
    { teks: "", gambar: null, jawaban_benar: false },
    { teks: "", gambar: null, jawaban_benar: false },
    { teks: "", gambar: null, jawaban_benar: false },
  ]);
  const [kd, setKd] = useState(null);
  const { selected: selectedMapel } = useSelector(
    (state) => state.appSetting.mapel
  );

  const addOpsi = () => {
    if (opsi.length < MAX_OPSI)
      setOpsi((opsi) => [
        ...opsi,
        { teks: "", gambar: null, jawaban_benar: false },
      ]);
  };

  const deleteOpsi = (index) => {
    if (opsi.length > MIN_OPSI) {
      setOpsi((opsi) => {
        let array = [...opsi];
        array.splice(index, 1);
        return array;
      });
    }
  };

  const fetchKompetensiDasar = useCallback(async () => {
    setLoading(true);
    try {
      let res = await kompetensiDasarService.getDataKompetensiDasar({
        mapel_id: selectedMapel.mapel_id,
      });
      setKd(res.data.results);
    } catch (error) {
      message.error("Tidak dapat mengambil data kompetensi dasar");
    }
    setLoading(false);
  }, [selectedMapel]);

  const fetchSoal = useCallback(async () => {
    if (!soalId) return;
    try {
      dispatch(setLoading(true));
      let { data } = await soalService.getDataSoalById(soalId);
      setSoal(() => ({
        teks: data.result.teks,
        gambar: data.result.gambar,
        kompetensi_dasar_id: data.result.kompetensi_dasar_id,
        jenis_soal: data.result.jenis_soal,
      }));
      let opsi_jawaban = JSON.parse(data.result.opsi_jawaban_id);
      let opsi = [];
      data.result.opsi.forEach((o) => {
        opsi.push({
          id: o.id,
          teks: o.teks,
          gambar: o.gambar,
          jawaban_benar: opsi_jawaban.includes(o.id),
        });
      });
      dispatch(setIsMultipleAnswer(opsi_jawaban.length > 1));
      setOpsi(opsi);
    } catch (error) {
      message.error("Tidak dapat mengambil data");
    } finally {
      dispatch(setLoading(false));
      return;
    }
  }, [soalId, dispatch]);

  const toggleMultipleAnswer = (isMultiple) => {
    if (!isMultiple)
      setOpsi((opsi) => opsi.map((o) => ({ ...o, jawaban_benar: false })));
    dispatch(setIsMultipleAnswer(isMultiple));
  };

  const validateForm = () => {
    let validateSoal = soal && (soal.teks !== "" || soal.gambar !== null);
    let validateOpsi = opsi.every(
      (o) => o && (o.teks !== "" || o.gambar !== null)
    );
    let validateAnswer = opsi.some((o) => o && o.jawaban_benar === true);
    let validateKD = soal.kompetensi_dasar_id !== null;

    if (!validateSoal) message.error("Pertanyaan tidak boleh kosong!");
    if (soal.jenis_soal === 1 && !validateOpsi)
      message.error("Semua opsi harus diisi!");
    if (soal.jenis_soal === 1 && !validateAnswer)
      message.error("Harus ada minimal 1 jawaban benar!");
    if (!validateKD) message.error("Mohon pilih kompetensi dasar!");

    if (soal.jenis_soal === 1)
      return validateSoal && validateOpsi && validateAnswer && validateKD;
    else return validateSoal && validateKD;
  };

  const submitHandler = async () => {
    if (!validateForm()) return;

    let payload = {
      sekolah_id: 1,
      mata_pelajaran_id: selectedMapel.mapel_id,
      jenjang: selectedMapel.tingkat,
      kompetensi_dasar_id: soal.kompetensi_dasar_id,
      teks: soal.teks,
      gambar: soal.gambar,
      jenis_soal: soal.jenis_soal,
      opsi: opsi,
    };

    dispatch(setLoading(true));

    try {
      if (!soalId) {
        await soalService.postSoal(payload);
      } else {
        await soalService.updateSoal(payload, soalId);
      }
      dispatch(setEditorVisible(false));
      onSuccess();
    } catch (error) {
      console.log(error.response);
      message.error("Terjadi kesalahan");
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (soalId) fetchSoal();
  }, [soalId, fetchSoal, selectedMapel]);

  useEffect(() => {
    fetchKompetensiDasar();
  }, [fetchKompetensiDasar, selectedMapel]);

  useEffect(() => {
    if (!selectedMapel || !selectedMapel.mapel_id || !selectedMapel.tingkat) {
      message.warning("Pilih mata pelajaran");
      dispatch(setEditorVisible(false));
    }
  }, [selectedMapel, dispatch]);

  return (
    <>
      <div className={styles["soal-editor-modal"]}>
        <SoalEditorToolbar />
        <Row justify="center" className={styles["editor-wrapper"]}>
          <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 18 }}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                <Form.Item label={"Kompetensi Dasar"}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Pilih kompetensi dasar"
                    value={{ value: soal.kompetensi_dasar_id }}
                    onChange={(value) =>
                      setSoal((soal) => ({
                        ...soal,
                        kompetensi_dasar_id: value,
                      }))
                    }
                  >
                    {kd &&
                      kd.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.kompetensi_dasar}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                <Form.Item label={"Jenis Soal"}>
                  <Select
                    defaultValue="1"
                    style={{
                      width: 220,
                    }}
                    onChange={(value) => {
                      setSoal((soal) => ({
                        ...soal,
                        jenis_soal: Number(value),
                      }));
                      console.log(soal.jenis_soal);
                    }}
                    options={[
                      {
                        value: "1",
                        label: "Pilihan Ganda",
                      },
                      {
                        value: "2",
                        label: "Essay",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <FormSoal soal={soal} setSoal={setSoal} />
            {soal.jenis_soal === 1 ? (
              <div className={styles["opsi-list"]}>
                {opsi.map((item, idx) => {
                  return (
                    <FormOpsi
                      key={idx}
                      opsi={opsi}
                      opsiIndex={idx}
                      setOpsi={setOpsi}
                      onDelete={deleteOpsi}
                    />
                  );
                })}
                <Button
                  disabled={opsi.length >= MAX_OPSI}
                  onClick={addOpsi}
                  icon={<PlusSquareFilled />}
                  className={styles["add-opsi-button"]}
                />
              </div>
            ) : (
              ""
            )}

            <div>
              <Row justify="space-between" align="middle">
                <Col>
                  {soal.jenis_soal === 1 ? (
                    <Card>
                      <Switch
                        checked={isMultipleAnswer}
                        onChange={toggleMultipleAnswer}
                        id="switch-multiple-answer"
                      />
                      <label
                        htmlFor="switch-multiple-answer"
                        style={{ marginLeft: "0.3rem", cursor: "pointer" }}
                      >
                        Lebih dari satu jawaban benar
                      </label>
                    </Card>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => dispatch(setEditorVisible(false))}
                    >
                      Batal
                    </Button>
                    <Button
                      type="primary"
                      onClick={submitHandler}
                      loading={loading}
                    >
                      Simpan
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <MathEditorModal />
      <ImageUploaderModal />
    </>
  );
}
