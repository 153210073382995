import { Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sekolahService from "../../../services/api/sekolah";
import { setSekolahId } from "../../../store/features/app-setting";

function SekolahSelectOption(props) {
  const appSetting = useSelector(state => state.appSetting);
  const dispatch = useDispatch();
  const [dataSekolah, setDataSekolah] = useState([]);

  const getDataSekolah = async (params = {}, signal = (new AbortController()).signal) => {
    return await sekolahService.getDataSekolah(params, signal);
  }

  const onSelectedSekolah = (value) => {
    dispatch(setSekolahId(value));
  }

  const onSearch = (keyword) => {
    getDataSekolah({limit: 10, offset: 0, search: keyword}).then(res => setDataSekolah(res.data.results));
  }

  const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    getDataSekolah({limit: 10, offset: 0}).then(res => setDataSekolah(res.data.results))
  }, [])

  return (
    <Select
      showSearch
      placeholder="Pilih Sekolah"
      optionFilterProp="children"
      filterOption={filterOption}
      onSelect={onSelectedSekolah}
      style={{ width: "300px" }}
      defaultValue={appSetting.sekolah_id}
      onSearch={onSearch}
    >
      {dataSekolah.map((item, index) => (
        <Select.Option key={index} value={item.id}>
          {item.nama}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SekolahSelectOption;