import axios from "axios";
import { API_URL } from "../../utils/config";

const sendEmail = async (data) => {
  let endpoint = `${API_URL}/reset-password/send-email`;
  return await axios.post(endpoint, data);
};

const resetPassword = async (data) => {
  let endpoint = `${API_URL}/reset-password`;
  return await axios.post(endpoint, data);
}

const resetPasswordService = {
  sendEmail,
  resetPassword
};

export default resetPasswordService;
