import axios from "axios";
import { API_URL } from "../../utils/config";
import qs from "qs";

const getHeaders = () => {
  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

const getDataSoal = async (params, config) => {
  let endpoint = `${API_URL}/soal?${qs.stringify(params)}`;
  return await axios.get(endpoint, { ...getHeaders(), ...config });
};

const getDataSoalById = async (id) => {
  let endpoint = `${API_URL}/soal/${id}`;
  return await axios.get(endpoint, { ...getHeaders() });
};

const postSoal = async (payload) => {
  let endpoint = `${API_URL}/soal`;
  return await axios.post(endpoint, payload, { ...getHeaders() });
};

const updateSoal = async (payload, id) => {
  let endpoint = `${API_URL}/soal/${id}`;
  return await axios.put(endpoint, payload, { ...getHeaders() });
};

const deleteSoal = async (id) => {
  let endpoint = `${API_URL}/soal/${id}`;
  console.log(id);
  return await axios.delete(endpoint, { ...getHeaders() });
};

const soalService = {
  postSoal,
  getDataSoal,
  getDataSoalById,
  updateSoal,
  deleteSoal,
};

export default soalService;
