import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/config";

export const isAuthenticated = createAsyncThunk('auth-user/authCheck', async (state, ...args) => {
  const token = localStorage.getItem("token");
  return await axios.get(`${API_URL}/user/authentication`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
})

const authUser = createSlice({
  name: "auth-user",
  initialState: {
    user: {},
    status: "idle",
    authenticated: false,
  },
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        user: action.payload,
        authenticated: true,
      };
    },
    resetData: (state, action) => {
      return {
        user: {},
        status: "idle",
        authenticated: false,
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(isAuthenticated.pending, (state, action) => {
        return {
          ...state,
          status: 'pending'
        }
      })
      .addCase(isAuthenticated.fulfilled, (state, action) => {
        return {
          ...state,
          status: 'fulfilled',
          authenticated: true,
          user: action.payload.data.user
        }
      })
      .addCase(isAuthenticated.rejected, (state, action) => {
        return {
          ...state,
          status: 'rejected',
          authenticated: false
        }
      })
  },
})

export const { setData, resetData } = authUser.actions

export default authUser.reducer